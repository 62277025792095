import React, { FC, useEffect, useState } from 'react';
import AgentTicket from '../../components/Main/AgentTicket/AgentTicket';
import AgentQueue from '../../components/Main/AgentQueue/AgentQueue';
import AgentButtonGroup from '../../components/Main/AgentButtunGroup/AgentButtonGroup';
import { AgentStatus, TicketStatus } from '../../types/enums';
import classes from './Agent1Page.module.scss';
import { ModalRedirection } from '../../components/Main/Modals/ModalRedirection/ModalRedirection';
import { useGetAgentQueue } from '../../hooks/Agents/useGetAgentQueue';
import { useGetUsedTicket } from '../../hooks/Agents/useGetUsedTicket';
import { useGetTicketComments } from '../../hooks/Agents/useGetTicketComments';
import ModalTicketInWorking from '../../components/Main/Modals/ModalTicketInWorking/ModalTicketInWorking';
import AgentsQueueNotification from '../../components/Main/AgentNotifications/AgentNotifications';
import { isNotificationEnabled } from '../../constants/API';

export type AgentsTicketType = {
  id: number;
  name: string;
  dateTime: string;
  status?: string; // todo waiting | fullfilled
  topicName: string;
};

export type TicketCommentsType = {
  map(arg0: (comment: string, index: number) => React.JSX.Element): React.ReactNode;
  comments: string[] | [];
};

export type TicketType = {
  comments: string[] | [];
  createdAt: string;
  itemId: number;
  name: string;
  requestedAt: string;
  status: string;
  topicName: string;
};

export type AgentsQueueType = {
  activeItems: AgentsTicketType[] | [];
  finishedItems: AgentsTicketType[] | [];
};

type Agent1PageProps = {
  isWorkPlaceSelected: boolean;
};

const Agent1Page: FC<Agent1PageProps> = ({ isWorkPlaceSelected }) => {
  const [activeTicket, setActiveTicket] = useState(null);
  const [agentStatus, setAgentStatus] = useState(AgentStatus.Waiting);
  const [timeToRequest, setTimeTorequest] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [isAgentWorkInQueue, setIsAgentWorkInQueue] = useState(true);
  const { agentQueueResp, isSuccessAgentQueue } = useGetAgentQueue(isWorkPlaceSelected);
  const { usedTicket, isSuccessUsedTicket } = useGetUsedTicket(isWorkPlaceSelected);
  const { ticketCommentsResp, isSuccessTicketComments } = useGetTicketComments(activeTicket?.id);
  const comments: TicketCommentsType = isSuccessTicketComments
    ? ticketCommentsResp?.data?.comments
    : [];
  const agentQueue = (isSuccessAgentQueue ? agentQueueResp?.data : null) as AgentsQueueType | null;
  const [queueStatus, setQueueStatus] = useState(null);
  useEffect(() => {
    agentQueue?.activeItems?.length > 0 ? setQueueStatus(true) : setQueueStatus(false);
  }, [agentQueue]);

  const ticket = (isSuccessUsedTicket ? usedTicket?.data : null) as TicketType | null;
  const [modalRedirectID, setModalRedirectID] = useState(null);
  const [isActiveModalInWorking, setActiveModalInWorking] = useState<boolean>(false);

  useEffect(() => {
    if (agentStatus === AgentStatus.Requesting) {
      setTimeTorequest(Date.now());
    }
    if (agentStatus === AgentStatus.Waiting) {
      setTimeTorequest(null);
    }
  }, [agentStatus]);

  useEffect(() => {
    if (ticket) {
      const { createdAt, itemId, name, topicName, requestedAt, status } = ticket;
      const viewedTicket = { id: itemId, name, dateTime: createdAt, topicName };
      setActiveTicket(viewedTicket);
      setTimeTorequest(requestedAt);
      setTicketStatus(status);
      if (status === TicketStatus.TakeInWorking) {
        setAgentStatus(AgentStatus.Working);
      }
      if (
        status === TicketStatus.CallFirstClientInQueue ||
        status === TicketStatus.CallPriorityClientinQueue ||
        status === TicketStatus.RepaetedCallClient
      ) {
        setAgentStatus(AgentStatus.Requesting);
      }
    }
  }, [ticket]);

  return (
    <div>
      <div className={classes.container}>
        {isNotificationEnabled && (
          <AgentsQueueNotification
            isAgentWorkInQueue={isAgentWorkInQueue}
            setIsAgentWorkInQueue={setIsAgentWorkInQueue}
          />
        )}

        <div>
          <AgentQueue
            agentTickets={agentQueue?.activeItems}
            setActiveTicket={setActiveTicket}
            activeTicket={activeTicket}
            agentStatus={agentStatus}
          />
        </div>
        <div className={classes.ticket}>
          <div className={classes.ticketContainer}>
            {activeTicket && (
              <AgentTicket
                timeToRequest={timeToRequest}
                ticket={activeTicket}
                agentStatus={agentStatus}
                comments={comments}
              />
            )}
          </div>
          <AgentButtonGroup
            queueStatus={queueStatus}
            ticketStatus={ticketStatus}
            agentStatus={agentStatus}
            handleChangeAgentStatus={setAgentStatus}
            ticketId={activeTicket && activeTicket.id}
            activeItems={agentQueue?.activeItems}
            setActiveTicket={setActiveTicket}
            setModalRedirectID={setModalRedirectID}
            setActiveModalInWorking={setActiveModalInWorking}
            isActiveModalInWorking={isActiveModalInWorking}
          />
        </div>
        <div>
          <AgentQueue agentTickets={agentQueue?.finishedItems} />
        </div>
        {modalRedirectID && (
          <ModalRedirection
            setActive={setModalRedirectID}
            itemId={activeTicket.id}
            handleChangeAgentStatus={setAgentStatus}
            setActiveTicket={setActiveTicket}
          />
        )}
        {isActiveModalInWorking && (
          <ModalTicketInWorking
            isActive={isActiveModalInWorking}
            setActive={setActiveModalInWorking}
          />
        )}
      </div>
    </div>
  );
};

export default Agent1Page;
