import { useMutation } from 'react-query';
import axios from 'axios';
import { queryClient } from '../../../queryClient';
import { WorkingPlacesTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { WorkingPlacesBodyType } from '../../../types/Admin/WorkingPlacesTypes';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { WorkPlaceType } from '../../../pagesAdmin/WorkplacesPage/WorkplacesPage';

export const useCreateWorkingPlace = (
  setActiveModal: (value: boolean) => void,
  setAgentsRequestData: (requestBody: WorkPlaceType | null) => void,
  setIsCreateMode?: (value: boolean) => void
) => {
  const context = useContext(AlertContext);
  const { mutate: createWorkingPlace, isLoading: isLoadingCreateWorkingPlace } = useMutation(
    (data: WorkingPlacesBodyType) => {
      return axios.post(WorkingPlacesTableAPI.postCreateWorkingPlace, data, commonHeaders());
    },
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries('workingPlaces')
          .then(() => setActiveModal(false))
          .then(() => setAgentsRequestData(null))
          .then(() => setIsCreateMode && setIsCreateMode(false))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { createWorkingPlace, isLoadingCreateWorkingPlace };
};
