import classNames from 'classnames';
import classes from './ModalTable.module.scss';
import React, { useState } from 'react';
import { Button } from '../../../Button/Button';
import { CustomTextField } from '../../../CustomTextField/CustomTextField';
import { crossIcon, downloadIcon } from '../../../../../assets/icons';
import { CreateNewVideoType } from '../../../../../hooks/Admin/Advertising/usePostVideo';

type ModalTableVideoProps = {
  modalVideoDownloadActive: boolean;
  isLoading: boolean;
  createNewVideo: (data: CreateNewVideoType) => void;
  setModalVideoDownloadActive: (activeStatus: boolean) => void;
};

export const ModalTableVideo = ({
  modalVideoDownloadActive,
  isLoading,
  createNewVideo,
  setModalVideoDownloadActive
}: ModalTableVideoProps) => {
  const [videoFile, setVideoFile] = useState(null);

  const handleVideoUpload = (event) => {
    const uploadedVideo = event.target.files[0];
    setVideoFile(uploadedVideo);
  };

  const handleSaveVideo = () => {
    if (videoFile) {
      createNewVideo({ file: videoFile });
      setVideoFile(null);
    }
  };

  return (
    <div
      className={classNames(classes.modal, { [classes.active]: modalVideoDownloadActive })}
      onClick={() => setModalVideoDownloadActive(false)}>
      <div
        className={classes.modalLicence}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <form className={classes.licenseForm}>
          {isLoading && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <p>Cохранение...</p>
              </div>
            </div>
          )}
          <h2>Загрузить видео</h2>
          <div className={classes.videoLoadContainer}>
            <CustomTextField labelName={'Выбранное видео'} disabled={true}>
              {videoFile && (
                <div className={classes.addedServiceItem}>
                  <div className={classes.crossIcon} onClick={() => setVideoFile(null)}>
                    <img src={crossIcon} alt={'cross'} />
                  </div>
                  {videoFile.name}
                </div>
              )}
            </CustomTextField>

            <label htmlFor="video" className={classes.videoDownloading}>
              <span>Загрузить видео</span>
              <img src={downloadIcon} alt={'download'} />
              <input
                type="file"
                id="video"
                name="video"
                accept="video/mp4,video/mkv"
                style={{ display: 'none' }}
                onChange={handleVideoUpload}
              />
            </label>
          </div>
          <div className={classes.formatTip}>
            <span>Доступные форматы: .MP4, .MKV</span>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              content={'отмена'}
              btnStyle={'white'}
              onClick={() => setModalVideoDownloadActive(false)}
            />
            <Button
              content={'сохранить'}
              isLoading={isLoading}
              btnStyle={'blue'}
              onClick={handleSaveVideo}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
