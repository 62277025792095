import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { AdminAPI } from '../../../constants/API';
import { queryClient } from '../../../queryClient';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { useContext } from 'react';
import { AlertContext } from '../../../context/AlertProvider';
import { AlertType } from '../../../types/enums';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { ErrorResponseDataType } from '../../../types/Admin/CategoriesTopicsTypes';
import { defaultErrorText } from '../../../types/Error/ErrorResponseType';

export type CreateNewUserType = {
  fullName: string;
  password: string;
  role: string;
};

export const usePostNewEmployer = (closeModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: createNewEmployer,
    isLoading: isCreateNewEmployerLoading,
    isError
  } = useMutation(
    ['newEmployer'],
    (data: CreateNewUserType) => axios.post(AdminAPI.postNewUser, data, commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('allEmployeesData');
        queryClient
          .invalidateQueries('allSystemTopics')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
          .then(() => closeModal(false));
      },
      onError: (error: AxiosError<ErrorResponseDataType>): void => {
        if (error instanceof AxiosError) {
          if (400 === error.response.status) {
            context?.setAlert({
              type: AlertType.Error,
              message: 'Такой пользователь уже существует'
            });
          } else {
            context?.setAlert({ type: AlertType.Error, message: error.response?.data.message });
          }
        } else {
          context?.setAlert({ type: AlertType.Error, message: defaultErrorText });
        }
      }
    }
  );

  return { createNewEmployer, isCreateNewEmployerLoading, isError };
};
