import * as React from 'react';
import styles from './SearchTextField.module.scss';
import { LegacyRef, TextareaHTMLAttributes } from 'react';
import { crossIconSecond } from '../../../assets/icons';

// eslint-disable-next-line react/display-name
export const SearchTextField = React.forwardRef(
  (
    {
      labelName,
      children,
      clearInput,
      ...rest
    }: TextareaHTMLAttributes<HTMLInputElement> & {
      clearInput?: () => void;
      labelName?: string;
      children?: React.ReactNode[];
    },
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };
    return (
      <div className={styles.searchTFcontainer}>
        <div className={styles.controller}>
          <input
            className={styles.searchInput}
            spellCheck="false"
            {...rest}
            ref={ref}
            onKeyDown={handleKeyDown}
          />
          <label className={styles.labelContainer}>{labelName}</label>
          <div className={styles.crossImage} onClick={() => clearInput()}>
            {<img src={crossIconSecond} alt={'cross'} />}
          </div>
        </div>
        <div className={styles.itemsContainer}>{children}</div>
      </div>
    );
  }
);
