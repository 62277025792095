import {
  CategoriesAndTopics,
  CategoriesTableType,
  Category,
  ServicesTableType
} from '../../../types/Admin/CategoriesTopicsTypes';

export const getCategoriesOrTopicsData = (categoriesAndTopicsData: CategoriesAndTopics) => {
  const topicsArr: ServicesTableType[] = [];
  const subCategoriesArr: CategoriesTableType[] = [];

  categoriesAndTopicsData.categories.forEach((category: Category) => {
    subCategoriesArr.push({
      id: category.id,
      nameRu: category.nameRu,
      categoryCounter: '0',
      topicCounter: category.topics.length.toString(),
      nameBe: category.nameBe,
      nameEn: category.nameEn,
      startFinishWork: `${category.startWork}-${category.endWork}`,
      radioType: 'section',
      isActive: category.isActive,
      topics: category.topics,
      isParentCategory: category.isParentCategory,
      ...(!category.isParentCategory && { parentCategory: category.parentCategory })
    });
  });

  const processCategory = (category: Category) => {
    if (category.topics && category.topics.length > 0) {
      category.topics.forEach((topic) => {
        topicsArr.push({
          id: topic.id,
          nameRu: topic.nameRu,
          categoryCounter: '0',
          topicCounter: '0',
          nameBe: topic.nameBe,
          nameEn: topic.nameEn,
          radioType: 'service',
          startFinishWork: `${topic.startWork}-${topic.endWork}`,
          isActiveTopic: topic.isActiveTopic,
          itemNamePrefix: topic.itemNamePrefix
        });
      });
    }
    if (category.subCategories) {
      category.subCategories.forEach((subCategory: Category) => {
        subCategoriesArr.push({
          id: subCategory.id,
          nameRu: subCategory.nameRu,
          categoryCounter: '0',
          topicCounter: subCategory.topics.length.toString(),
          nameBe: subCategory.nameBe,
          nameEn: subCategory.nameEn,
          startFinishWork: `${subCategory.startWork}-${subCategory.endWork}`,
          radioType: 'section',
          isActive: subCategory.isActive,
          topics: subCategory.topics,
          isParentCategory: subCategory.isParentCategory,
          ...(!subCategory.isParentCategory && { parentCategory: subCategory.parentCategory })
        });
        processCategory(subCategory);
      });
    }
  };

  categoriesAndTopicsData?.categories?.forEach((category: Category) => {
    processCategory(category);
  });

  if (categoriesAndTopicsData.unusedTopics && categoriesAndTopicsData.unusedTopics.length > 0) {
    categoriesAndTopicsData.unusedTopics.forEach((topic) => {
      topicsArr.push({
        id: topic.id,
        nameRu: topic.nameRu,
        categoryCounter: '0',
        topicCounter: '0',
        nameBe: topic.nameBe,
        nameEn: topic.nameEn,
        radioType: 'service',
        startFinishWork: `${topic.startWork}-${topic.endWork}`,
        isActiveTopic: topic.isActiveTopic,
        itemNamePrefix: topic.itemNamePrefix
      });
    });
  }

  const uniqueTopicsArr = topicsArr.reduce((acc: ServicesTableType[], curr: ServicesTableType) => {
    const isDuplicate = acc.some((topic) => topic.id === curr.id || topic.nameRu === curr.nameRu);
    if (!isDuplicate) {
      return [...acc, curr];
    }
    return acc;
  }, []);

  return { categoriesArr: subCategoriesArr, topicsArr: uniqueTopicsArr };
};
