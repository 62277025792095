import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import classes from './TerminalModal.module.scss';
import { LoadingPrintSpinner } from '../../../Admin/Spinner/LoadingPrintSpinner';
import { terminalTicketIcon } from '../../../../assets/icons';

type TerminalModalType = {
  activeModal: boolean;
  setActiveModal: (value: boolean) => void;
};

const TerminalModal: FC<TerminalModalType> = ({ activeModal, setActiveModal }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setActiveModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={modalRef} className={classNames(classes.modal, { [classes.active]: activeModal })}>
      <div className={classes.modalContainer}>
        <img className={classes.spinnerIcon} width={18} src={terminalTicketIcon} alt="TicketIcon" />
        <LoadingPrintSpinner />
      </div>
    </div>
  );
};

export default TerminalModal;
