import React, { FC, useEffect, useRef, useState } from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';
import classes from './TickersTable.module.scss';
import { checkIcon, modifierIcon } from '../../../../../assets/icons';
import Skeleton from '../../../Skeleton/Skeleton';
import { ActionMenu } from '../../../ActionMenu/ActionMenuGeneral/ActionMenu';
import { ModalDeleteUse } from '../../../Modals/ModalDeleteUse/ModalDeleteUse';
import { ActivateContent, ModalMode } from '../../../../../types/enums';
import { ActivateContentType } from '../../../../../hooks/Admin/Advertising/usePutActivateContent';
import { handleClickOutside } from '../../../../../utils/Admin/handleClickOutside';

export type TickersDataTableType = {
  id: number;
  text: string;
  isActive: boolean;
};

const columnSizes = {
  textWidth: 578,
  isActiveWidth: 94
};
type RunningQweryTableProps = {
  setActiveQweryRowData: (data: TickersDataTableType) => void;
  setEditTickerModalActive: (status: boolean) => void;
  setEditTickerModalMode: (mode: ModalMode.Create | ModalMode.Edit) => void;
  activateContent: (data: ActivateContentType) => void;
  setDeleteModalActive: (status: boolean) => void;
  deleteTicker: (tickerId: number) => void;
  deleteModalActive: boolean;
  tickersGalleryData: TickersDataTableType[];
  isLoadingData: boolean;
  isLoadingDeleteTicker: boolean;
};

const { textWidth, isActiveWidth } = columnSizes;
const columnHelper = createColumnHelper<TickersDataTableType>();

const TickersTable: FC<RunningQweryTableProps> = ({
  setActiveQweryRowData,
  setEditTickerModalActive,
  setEditTickerModalMode,
  activateContent,
  setDeleteModalActive,
  deleteTicker,
  deleteModalActive,
  tickersGalleryData,
  isLoadingData,
  isLoadingDeleteTicker
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [activeRowId, setActiveRowId] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) =>
      handleClickOutside(event, menuElement, setOpenMenuId)
    );
    return () => {
      document.removeEventListener('mousedown', (event: MouseEvent) =>
        handleClickOutside(event, menuElement, setOpenMenuId)
      );
    };
  }, [setOpenMenuId]);

  const handleButtonClick = () => {
    setEditTickerModalMode(ModalMode.Edit);
    setEditTickerModalActive(true);
    setOpenMenuId(null);
  };

  const handleOpenDeleteModal = (data: TickersDataTableType) => {
    setActiveQweryRowData(data);
    setActiveRowId(data.id);
    setDeleteModalActive(true);
    setOpenMenuId(null);
  };

  const handleClick = (id: string) => {
    setActiveRow(id);
  };
  const columns = [
    columnHelper.accessor('text', {
      header: () => <div className={classes.headerFirstColumnContent}>Наименование</div>,
      cell: ({ getValue, row }) => (
        <div className={classes.bodyFirstColumnContent}>
          <div className={classes.editMenuContainer}>
            <img
              src={modifierIcon}
              id={'imgMenu'}
              alt="modifierService"
              className={classes.modifierImage}
              onClick={() => {
                if (openMenuId === row.original.id) {
                  setOpenMenuId(null);
                } else {
                  setOpenMenuId(row.original.id);
                }
                setActiveQweryRowData(row.original);
              }}
            />
            {openMenuId === row.original.id && (
              <div ref={menuElement} className={classes.actionMenuContainer}>
                <ActionMenu
                  editOnClick={handleButtonClick}
                  isActivate={row.original.isActive}
                  activateDeactivateClick={() => {
                    activateContent({
                      contentId: row.original.id,
                      body: {
                        state: row.original.isActive
                          ? ActivateContent.Deactivate
                          : ActivateContent.Activate
                      }
                    });
                    setOpenMenuId(null);
                  }}
                  deleteOnClick={() => handleOpenDeleteModal(row?.original)}
                />
              </div>
            )}
          </div>
          <div className={classes.firstColumnText}>{getValue()}</div>
        </div>
      ),
      size: textWidth
    }),
    columnHelper.accessor('isActive', {
      header: () => <div className={classes.headerContentCenter}>Активный</div>,
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.columnContentCenter}>
            <img src={checkIcon} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      size: isActiveWidth
    })
  ];

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : tickersGalleryData || []),
    [isLoadingData, tickersGalleryData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.container}>
      {deleteModalActive && (
        <ModalDeleteUse
          isActive={deleteModalActive}
          setActive={setDeleteModalActive}
          isDeleteLoading={isLoadingDeleteTicker}
          deleteItem={deleteTicker}
          id={activeRowId}
        />
      )}

      <div className={classes.tableContainer}>
        <table className={classes.tableParkingSpace}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => handleClick(row.id)}
                className={activeRow === row.id ? classes.activeRow : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TickersTable;
