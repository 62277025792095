import { useMutation } from 'react-query';
import axios from 'axios';
import { AdvertisingAPI } from '../../../constants/API';
import { queryClient } from '../../../queryClient';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { useContext } from 'react';
import { AlertContext } from '../../../context/AlertProvider';
import { AlertType } from '../../../types/enums';
import { defaultSuccessMessage } from '../../../constants/alertText';

export type CreateNewTickerType = {
  text: string;
};

export const usePostTicker = (closeModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: createNewTicker,
    isLoading: isCreateNewTickerLoading,
    isError
  } = useMutation(
    ['newTicker'],
    (data: CreateNewTickerType) => axios.post(AdvertisingAPI.postTicker, data, commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AdvertisingSettings');
        queryClient
          .invalidateQueries('allAdvertisingTickers')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
          .then(() => closeModal(false));
      }
    }
  );

  return { createNewTicker, isCreateNewTickerLoading, isError };
};
