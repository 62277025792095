import React, { FC, useState } from 'react';
import MainHeader from '../../components/Main/Header/MainHeader';
import ModalWorkplace from '../../components/Main/Modals/ModalSelectWorkplace/ModalWorkplace';

import MainRoutes from '../../components/Routes/MainRoutes';
import styles from './MainLayout.module.scss';

type MainLayoutProps = {
  setIsAuthenticated: (status: boolean) => void;
};

const MainLayout: FC<MainLayoutProps> = ({ setIsAuthenticated }) => {
  const [activeModal, setActiveModal] = useState(true);
  const [isWorkPlaceSelected, setWorkPlaceSelected] = useState(false);
  return (
    <div className={styles.mainContainer}>
      <MainHeader setIsAuthenticated={setIsAuthenticated} />
      <MainRoutes isWorkPlaceSelected={isWorkPlaceSelected} />
      <ModalWorkplace
        isActive={activeModal}
        setActiveModal={setActiveModal}
        setWorkPlaceSelected={setWorkPlaceSelected}
      />
    </div>
  );
};

export default MainLayout;
