import { homeRoutesURLs, mainRoutesURLs } from './routes';

export const rolesURLs = [
  {
    role: 'Оператор',
    path: `main/${mainRoutesURLs.agent1PagePath}`
  },
  {
    role: 'Администратор',
    path: homeRoutesURLs.adminPagePath
  }
];
