import { useContext } from 'react';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AlertType } from '../../../types/enums';
import { AdvertisingAPI } from '../../../constants/API';
import { AlertContext } from '../../../context/AlertProvider';
import { defaultSuccessMessage } from '../../../constants/alertText';

export const useDeleteVideo = (closeModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: deleteVideo,
    isLoading: isDeleteVideoLoading,
    isError
  } = useMutation(
    'deletedVideoStatus',
    (videoId: number) => axios.delete(AdvertisingAPI.deleteVideo(videoId), commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AdvertisingSettings');
        queryClient
          .invalidateQueries('allAdvertisingVideos')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
          .then(() => closeModal(false));
      }
    }
  );

  return { deleteVideo, isDeleteVideoLoading, isError };
};
