import { QueryClient, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

type categoryDataType = {
  nameRu: string;
  nameBe: string;
  nameEn: string;
  isParentCategory: boolean;
  parentCategoryId?: number;
  topics?: number[] | [];
  startWork: string;
  endWork: string;
};
export const useEditCategory = (
  closeCreateCategoryModal: (value: boolean) => void,
  categoryId: number
) => {
  const client: QueryClient = useQueryClient();
  const context = useContext(AlertContext);
  const { mutate: editCategory, isLoading: isLoadingCategoryEditing } = useMutation(
    (data: categoryDataType) => {
      return axios.put(
        CategoriesTopicsTableAPI.postEditCategory(categoryId),
        data,
        commonHeaders()
      );
    },
    {
      onSuccess() {
        client
          .invalidateQueries('allCategoriesTopics')
          .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
          .then(() => closeCreateCategoryModal(false))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { editCategory, isLoadingCategoryEditing };
};
