import classes from './ModalLicense.module.scss';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Button } from '../../../Button/Button';

type ModalLicenseProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
};
export const ModalLicence: FC<ModalLicenseProps> = ({ isActive, setActive }) => {
  return (
    <div
      className={classNames(classes.modal, { [classes.active]: isActive })}
      onClick={() => setActive(false)}>
      <div
        className={classes.modalLicence}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <form className={classes.licenseForm}>
          <h2>Лицензия</h2>
          <div className={classes.textContainer}>
            <p>
              Лицензия предоставляет возможность пользоваться предустановленным программным
              обеспечением на определенный период. В лицензию не входит автоматическое обновление
              программы «Система управления электронной очередью» TEHNOSKY System». Все доработки и
              нововведения обсуждаются индивидуально с каждым заказчиком.
            </p>
            <p className={classes.secondP}>
              Лицензия может быть как на месяц так и на год, два, три, пять. Более длительный срок
              согласовывается в индивидуальном порядке.
            </p>
            <p className={classes.thirdP}>
              Подробную информацию можно получить по тел. +375(29) 118 77 72
            </p>
          </div>
          <div className={classes.btnContainer}>
            <Button content={'закрыть'} btnStyle={'blue'} onClick={() => setActive(false)} />
          </div>
        </form>
      </div>
    </div>
  );
};
