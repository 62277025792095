import classes from './ModalSectionService.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { SectionContent } from './SectionContent/SectionContent';
import { ServiceContent } from './ServiceContent/ServiceContent';
import * as React from 'react';
import classNames from 'classnames';
import { modalsSectionServiceDataType } from '../../../../types/Admin/CategoriesTopicsTypes';
import { SectionOrCategory } from '../../../../types/enums';

type ModalSectionServiceProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
  activeRowData?: modalsSectionServiceDataType;
  modalMode: string;
};
export const ModalSectionService: FC<ModalSectionServiceProps> = ({
  isActive,
  setActive,
  activeRowData,
  modalMode
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(SectionOrCategory.Section);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if (activeRowData && activeRowData.radioType) {
      setSelectedValue(activeRowData.radioType);
    } else {
      setSelectedValue(SectionOrCategory.Section);
    }
  }, [activeRowData.radioType]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames(classes.modal, { [classes.active]: isActive })}>
      <div
        className={classNames({
          [classes.chapterContainer]: selectedValue === SectionOrCategory.Section,
          [classes.serviceContainer]: selectedValue !== SectionOrCategory.Section
        })}
        ref={modalRef}>
        {selectedValue === SectionOrCategory.Section ? (
          <SectionContent
            isActive={isActive}
            selectedRadioValue={selectedValue}
            handleRadioChange={handleRadioChange}
            setActiveModal={setActive}
            modalDataFromTable={activeRowData}
            modalMode={modalMode}
          />
        ) : (
          <ServiceContent
            selectedRadioValue={selectedValue}
            handleRadioChange={handleRadioChange}
            setActiveModal={setActive}
            modalDataFromTable={activeRowData}
            modalMode={modalMode}
          />
        )}
      </div>
    </div>
  );
};
