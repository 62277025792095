import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Agent1Page from '../../pagesMain/Agent1Page/Agent1Page';
import Page404 from '../../pagesAdmin/Page404/Page404';
import { mainRoutesURLs } from '../../constants/routes';

type MainRoutesProps = {
  isWorkPlaceSelected: boolean;
};

const MainRoutes: FC<MainRoutesProps> = ({ isWorkPlaceSelected }) => {
  const { agent1PagePath } = mainRoutesURLs;

  return (
    <div>
      <Routes>
        <Route
          path={agent1PagePath}
          element={<Agent1Page isWorkPlaceSelected={isWorkPlaceSelected} />}
        />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </div>
  );
};

export default MainRoutes;
