import { useQuery } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { AuthorizationAPI } from '../../constants/API';

export const useGetWorkplacesList = () => {
  const {
    data: userWorkplacesResp,
    isFetching: isLoadingUserWorkplaces,
    isSuccess: isUserWorkplacesRespRecived
  } = useQuery(
    ['userWorkplaces'],
    () => axios.get(AuthorizationAPI.getUserWorkplaces, commonHeaders()),
    {}
  );
  return { userWorkplacesResp, isLoadingUserWorkplaces, isUserWorkplacesRespRecived };
};
