import React, { useEffect, useRef, useState } from 'react';
import classes from './ModalAssignServices.module.scss';
import { SubmitHandler, useForm } from 'react-hook-form';

import classNames from 'classnames';
import { Button } from '../../Button/Button';
import { CustomTextField } from '../../CustomTextField/CustomTextField';
import { crossIcon } from '../../../../assets/icons';
import { AssignedServicesType } from '../../../../pagesAdmin/EmployersPage/EmployersPage';
import { SearchTextField } from '../../SearchTextField/SearchTextField';
import {
  WorkingPlacesBodyType,
  WorkingPlacesRequestBodyType
} from '../../../../types/Admin/WorkingPlacesTypes';
import { WorkPlaceType } from '../../../../pagesAdmin/WorkplacesPage/WorkplacesPage';
import { workingPlaceType } from '../../Tables/WorkingPlacesTable/WorkingPlacesTable';

type FormData = {
  searchText: string;
};

type serviceType = {
  id: number;
  nameRu: string;
};

type ModalAccessLevelsProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
  allSystemTopics: AssignedServicesType[];
  assignedServices?: AssignedServicesType[];
  setAssignedServices: (servicesList: AssignedServicesType[]) => void;
  availableServices?: AssignedServicesType[];
  setAvailableServices: (servicesList: AssignedServicesType[]) => void;
  setBackStage: (stage: boolean) => void;
  setModalActive: (status: boolean) => void;
  createWorkingPlace: (item: WorkingPlacesBodyType) => void;
  editWorkingPlace: (item: WorkingPlacesRequestBodyType) => void;
  isLoadingEditWorkingPlace: boolean;
  isLoadingCreateWorkingPlace: boolean;
  agentsRequestData: WorkPlaceType;
  setAgentsRequestData: (requestBody: WorkPlaceType | null) => void;
  setActiveRowData: (value: null | workingPlaceType) => void;
  isCreateMode: boolean;
  setIsCreateMode: (status: boolean) => void;
};

export const ModalAccessLevels = ({
  isActive,
  setActive,
  availableServices: list1,
  setAvailableServices: setList1,
  assignedServices: list2,
  setAssignedServices: setList2,
  allSystemTopics,
  agentsRequestData,
  isCreateMode,
  setIsCreateMode,
  isLoadingEditWorkingPlace,
  isLoadingCreateWorkingPlace,
  setAgentsRequestData,
  createWorkingPlace,
  editWorkingPlace,
  setBackStage,
  setModalActive,
  setActiveRowData
}: ModalAccessLevelsProps) => {
  const [servicesArray, setServicesArray] = useState(allSystemTopics);
  const modalRef = useRef<HTMLDivElement>(null);

  const [searchText, setSearchText] = useState('');
  const filteredList = list1.filter((item) =>
    item.nameRu.toLowerCase().includes(searchText.toLowerCase())
  );

  const moveItem = (
    item: serviceType,
    fromList: AssignedServicesType[],
    toList: AssignedServicesType[]
  ) => {
    const index = fromList.indexOf(item);
    const updatedFromList = [...fromList.slice(0, index), ...fromList.slice(index + 1)];
    const updatedToList = [...toList, item];
    fromList === list1 ? setList1(updatedFromList) : setList2(updatedFromList);
    fromList === list1 ? setList2(updatedToList) : setList1(updatedToList);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  function filterArray(arr1: AssignedServicesType[], arr2: AssignedServicesType[]) {
    return arr1.filter((obj1) => !arr2.some((obj2) => obj1.id == obj2.id));
  }

  useEffect(() => {
    if (list2 && list2.length) {
      const filteredSystemTopics = filterArray(allSystemTopics, list2);

      setList1(filteredSystemTopics);
    }
  }, [list2, allSystemTopics]);

  const { register, handleSubmit } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      searchText: ''
    }
  });
  const filterServices = (searchValue: string): void => {
    const filteredItems = servicesArray?.filter((item) => {
      return item.nameRu.toLowerCase().includes(searchValue.toLowerCase());
    });
    setServicesArray(filteredItems);
  };

  const onSubmit: SubmitHandler<FormData> = async () => {
    const agentsRequestBody = {
      type: agentsRequestData.type,
      description: agentsRequestData.description,
      digitalDisplayIp: agentsRequestData.digitalDisplayIp,
      officeNumber: agentsRequestData.officeNumber || null,
      windowNumber: agentsRequestData.windowNumber || null,
      users: agentsRequestData.assignedEmployeesIds || [],
      topics: list2.map((e: serviceType) => e.id)
    };

    setBackStage(false);
    if (agentsRequestData?.id) {
      editWorkingPlace({ requestBody: agentsRequestBody, id: agentsRequestData.id });
    }
    if (!agentsRequestData?.id) {
      createWorkingPlace(agentsRequestBody);
    }
    setActiveRowData(null);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setActive(false);
      setBackStage(false);
      setAgentsRequestData(null);
      setList2([]);
      setIsCreateMode(false);
      setActiveRowData(null);
    }
  };

  const handleClickBack = () => {
    setActive(false);
    setModalActive(true);
    setBackStage(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={classNames(classes.modal, { [classes.active]: isActive })}>
      <div className={classes.unlimitedAgentContainer} ref={modalRef}>
        <form className={classes.unlimitedAgentForm}>
          {(isLoadingEditWorkingPlace || isLoadingCreateWorkingPlace) && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <p>Cохранение...</p>
              </div>
            </div>
          )}
          <h2>{isCreateMode ? 'Создание рабочего места' : 'Редактирование рабочего места'}</h2>
          <div>
            <div className={classes.addContainer}>
              <div className={classes.searchItemContainer}>
                <SearchTextField
                  {...register('searchText')}
                  name="searchText"
                  labelName={'Добавить услугу'}
                  placeholder={'Введите название'}
                  clearInput={(): void => {
                    setSearchText('');
                    filterServices('');
                  }}
                  value={searchText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e)}>
                  {filteredList.map((item) => (
                    <div
                      key={item.id}
                      className={classes.serviceItem}
                      onClick={() => moveItem(item, list1, list2)}>
                      {item.nameRu}
                    </div>
                  ))}
                </SearchTextField>
              </div>
              <div className={classes.addedItemContainer}>
                <CustomTextField labelName={'Добавленные услуги'} disabled={true}>
                  {list2.map((item) => (
                    <div
                      key={item.id}
                      className={classes.addedServiceItem}
                      onClick={() => moveItem(item, list2, list1)}>
                      <img src={crossIcon} alt={'cross'} />
                      {item.nameRu}
                    </div>
                  ))}
                </CustomTextField>
              </div>
            </div>
            <div className={classes.buttonsContainer}>
              <Button content={'назад'} btnStyle={'white'} onClick={() => handleClickBack()} />
              <div className={classes.mainBtnsContainer}>
                <Button
                  content={'отмена'}
                  btnStyle={'white'}
                  onClick={() => {
                    setActive(false);
                    setBackStage(false);
                    setAgentsRequestData(null);
                    setList2([]);
                    setIsCreateMode(false);
                    setActiveRowData(null);
                  }}
                />
                <Button
                  content={'сохранить'}
                  btnStyle={'blue'}
                  onClick={handleSubmit(onSubmit)}
                  isLoading={isLoadingEditWorkingPlace || isLoadingCreateWorkingPlace}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
