import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getExpandedRowModel
} from '@tanstack/react-table';
import { arrowDownIcon, checkIcon, modifierIcon } from '../../../../../assets/icons';
import classes from './AllItemsTable.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { ActionMenu } from '../../../ActionMenu/ActionMenuGeneral/ActionMenu';
import { handleClickOutside } from '../../../../../utils/Admin/handleClickOutside';
import { useActivateDeactivateCategory } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useActivateDeactivateCategory';
import {
  CategoriesServicesObjectData,
  SectionOrServiceForTable,
  modalsSectionServiceDataType
} from '../../../../../types/Admin/CategoriesTopicsTypes';
import { useActivateDeactivateTopic } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useActivateDeactivateTopic';
import classNames from 'classnames';
import { getTopicsByCategoryId } from '../../../../../utils/Admin/CategoriesAndServicesTable/getTopicsByCategoryId';
import { ModalMode } from '../../../../../types/enums';
import Skeleton from '../../../Skeleton/Skeleton';

const columnSizes = {
  nameRuWidth: 425,
  sectionWidth: 74,
  servicesWidth: 72,
  nameBeWidth: 348,
  nameEnWidth: 348,
  itemNamePrefixWidth: 95,
  startFinishWorkWidth: 123,
  isActiveWidth: 94
};
const {
  nameRuWidth,
  sectionWidth,
  servicesWidth,
  nameBeWidth,
  nameEnWidth,
  itemNamePrefixWidth,
  startFinishWorkWidth,
  isActiveWidth
} = columnSizes;

type AllItemsTableProps = {
  activeRowData?: modalsSectionServiceDataType;
  setActiveRowData: (data: SectionOrServiceForTable) => void;
  setModalActive: (value: boolean) => void;
  setModalDeleteActive: (value: boolean) => void;
  tableData: CategoriesServicesObjectData;
  setModalMode: (value: string) => void;
  isLoadingData: boolean;
};

const AllItemsTable: FC<AllItemsTableProps> = ({
  activeRowData,
  setActiveRowData,
  setModalActive,
  tableData,
  setModalDeleteActive,
  setModalMode,
  isLoadingData
}) => {
  const categoriesServicesData = tableData?.all || [];
  const columnHelper = createColumnHelper<SectionOrServiceForTable>();
  const [expanded, setExpanded] = React.useState({});
  const [activeRow, setActiveRow] = React.useState('0');
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const { activateOrDeactivateCategory } = useActivateDeactivateCategory(setOpenMenuId);
  const { activateOrDeactivateTopic } = useActivateDeactivateTopic(setOpenMenuId);

  const menuElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) =>
      handleClickOutside(event, menuElement, setOpenMenuId)
    );
    return () => {
      document.removeEventListener('mousedown', (event: MouseEvent) =>
        handleClickOutside(event, menuElement, setOpenMenuId)
      );
    };
  }, [setOpenMenuId]);

  function handleEditClick() {
    setModalActive(true);
    setOpenMenuId(null);
  }

  function handleOpenDeleteModal() {
    setModalDeleteActive(true);
    setOpenMenuId(null);
  }

  const handleActivateDeactivateCategoryClick = (rowId: number, actionType: string) => {
    activateOrDeactivateCategory({ categoryId: rowId, data: { state: actionType } });
  };

  const handleActivateDeactivateTopicClick = (rowId: number, actionType: string) => {
    activateOrDeactivateTopic({ topicId: rowId, data: { state: actionType } });
  };

  const allColumns = [
    columnHelper.accessor('nameRu', {
      header: () => <div className={classes.headerFirstColumnContent}>Наименование</div>,
      cell: ({ row, getValue }) => (
        <div
          key={row.id}
          className={classes.bodyFirstColumnContent}
          style={{
            paddingLeft: `${row.depth}rem`
          }}>
          <>
            <div className={classes.editMenuContainer}>
              <img
                id={'imgMenu'}
                src={modifierIcon}
                alt="modifierService"
                className={classes.modifierImage}
                onClick={() => {
                  if (openMenuId === row.id) {
                    setOpenMenuId(null);
                  } else {
                    setOpenMenuId(row.id);
                  }
                  row.original.radioType === 'section' &&
                    (row.original.topics = getTopicsByCategoryId(
                      tableData.sections,
                      row.original.id
                    ));
                  setActiveRowData(row.original);
                  setModalMode(ModalMode.Edit);
                }}
              />
              {openMenuId && openMenuId === row.id && (
                <div ref={menuElement} className={classes.actonMenuContainer}>
                  <ActionMenu
                    isActivate={
                      row.original.radioType === 'section'
                        ? row.original.isActive
                        : row.original.isActiveLinkToCategory
                    }
                    editOnClick={handleEditClick}
                    deleteOnClick={() => {
                      handleOpenDeleteModal();
                    }}
                    activateDeactivateClick={
                      row.original.radioType === 'section'
                        ? () =>
                            handleActivateDeactivateCategoryClick(
                              row.original.id,
                              row.original.isActive ? 'Деактивировать' : 'Активировать'
                            )
                        : () =>
                            handleActivateDeactivateTopicClick(
                              row.original.id,
                              row.original.isActiveLinkToCategory
                                ? 'Деактивировать'
                                : 'Активировать'
                            )
                    }
                  />
                </div>
              )}
            </div>
            {row.getCanExpand() || row.original.radioType === 'section' ? (
              <button
                className={classes.expandedBtn}
                {...{
                  onClick: row.getToggleExpandedHandler()
                }}>
                {row.getIsExpanded() ? (
                  <img
                    src={arrowDownIcon}
                    alt="openExpanding"
                    className={classNames(classes.iconSize, classes.rotate)}
                  />
                ) : (
                  <img src={arrowDownIcon} alt="openExpanding" className={classes.iconSize} />
                )}
              </button>
            ) : (
              ' '
            )}{' '}
            <div className={classes.firstColumnText}>{getValue()}</div>
          </>
        </div>
      ),
      size: nameRuWidth
    }),
    columnHelper.accessor('categoryCounter', {
      header: () => <div className={classes.headerContentCenter}>Раздел</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: sectionWidth
    }),
    columnHelper.accessor('topicCounter', {
      header: () => <div className={classes.headerContentCenter}>Услуги</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: servicesWidth
    }),
    columnHelper.accessor('nameBe', {
      header: () => <div className={classes.headerContent}>Наименование BE</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: nameBeWidth
    }),
    columnHelper.accessor('nameEn', {
      header: () => <div className={classes.headerContent}>Наименование EN</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: nameEnWidth
    }),
    columnHelper.accessor('itemNamePrefix', {
      header: () => <div className={classes.headerContentCenter}>Буква при выдаче</div>,
      cell: (info) => <div className={classes.columnContentCenter}>{info.getValue()}</div>,
      size: itemNamePrefixWidth
    }),
    columnHelper.accessor('startFinishWork', {
      header: () => <div className={classes.headerContentCenter}>Начало-конец работы</div>,
      cell: (info) => <div className={classes.columnContentCenter}>{info.getValue()}</div>,
      size: startFinishWorkWidth
    }),
    columnHelper.accessor(
      activeRowData && activeRowData.radioType && activeRowData.radioType === 'section'
        ? 'isActive'
        : 'isActiveLinkToCategory',
      {
        header: () => <div className={classes.headerContentCenter}>Активный</div>,
        cell: ({ row }) => (
          <div className={classes.columnContentCenter}>
            {row.original.radioType === 'section'
              ? row.original.isActive && <img src={checkIcon} alt="active" />
              : row.original.isActiveLinkToCategory && <img src={checkIcon} alt="active" />}
          </div>
        ),
        size: isActiveWidth
      }
    )
  ];

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const columns = allColumns;

  const tableDataFinal = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : categoriesServicesData),
    [isLoadingData, categoriesServicesData]
  );

  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableDataFinal,
    columns: tableColumns,
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  });

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => {
                  handleClick(row.id);
                }}
                className={activeRow === row.id ? classes.activeRow : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllItemsTable;
