import React, { FC } from 'react';
import classNames from 'classnames';
import { useLogout } from '../../../../hooks/Authorization/useLogout';

import classes from './ModalLogout.module.scss';

type ModalLogout = {
  activeLogout: boolean;
  setActiveLogout: (value: boolean) => void;
  setIsAuthenticated: (status: boolean) => void;
};

const ModalLogout: FC<ModalLogout> = ({ activeLogout, setActiveLogout, setIsAuthenticated }) => {
  const { logout } = useLogout(setIsAuthenticated);
  const handleClickConfirm = () => {
    logout();
    setActiveLogout(false);
  };
  const handleClickCancel = () => {
    setActiveLogout(false);
  };
  return (
    <div className={classNames(classes.modal, { [classes.active]: activeLogout })}>
      <div className={classes.modalContainer}>
        <div className={classes.title}>Выйти из системы?</div>
        <div className={classes.btnContainer}>
          <button
            className={classNames(classes.btnCansel, classes.btn)}
            onClick={handleClickCancel}>
            нет
          </button>
          <button
            className={classNames(classes.btnConfirm, classes.btn)}
            onClick={handleClickConfirm}>
            да
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalLogout;
