import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';

import classes from './CurentServicesStatusTables.module.scss';
import { FC } from 'react';
import Skeleton from '../../../Skeleton/Skeleton';

type CurrentServicesStatusTableType = {
  serviceName: string;
  queue: string;
  queuingTime: string;
};

type CurrentServicesStatusTableQueueProps = {
  QueueTableData: CurrentServicesStatusTableType[] | [];
  isLoadingData: boolean;
};

const columnSizes = {
  serviceNameSize: 200,
  queueSize: 200,
  queuingTimeSize: 1200, //starting column size
  queuingTimeMinSize: 50 //enforced during column resizing
};

const { serviceNameSize, queueSize, queuingTimeSize, queuingTimeMinSize } = columnSizes;

const CurrentServicesStatusTableQueue: FC<CurrentServicesStatusTableQueueProps> = ({
  QueueTableData,
  isLoadingData
}) => {
  const columnHelper = createColumnHelper<CurrentServicesStatusTableType>();

  const categoriesColumns = [
    columnHelper.accessor('serviceName', {
      header: () => <div className={classes.headerContent}>Название услуги</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: serviceNameSize
    }),
    columnHelper.accessor('queue', {
      header: () => <div className={classes.headerContent}>Количество человек в очереди</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: queueSize
    }),
    columnHelper.accessor('queuingTime', {
      header: () => (
        <div className={classes.headerContentRight}>Сред. время ожидания в очереди</div>
      ),
      cell: ({ getValue }) => <div className={classes.columnContentRight}>{getValue()}</div>,
      size: queuingTimeSize, //starting column size
      minSize: queuingTimeMinSize //enforced during column resizing
    })
  ];

  const columns = categoriesColumns;
  const [activeRow, setActiveRow] = React.useState(null);

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : QueueTableData || []),
    [isLoadingData, QueueTableData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: `${header.getSize()}px` }}>
                    <div className={classes.thTitle}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                        }}>
                        <div className={classes.colorResizer} />
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => handleClick(row.id)}
              className={activeRow === row.id ? classes.activeRow : ''}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CurrentServicesStatusTableQueue;
