import {
  eyeGlassesIcon,
  terminalHeaderByLangIcon,
  terminalHeaderCalendarIcon,
  terminalHeaderClockIcon,
  terminalHeaderEnLangIcon,
  terminalHeaderLogoIcon,
  terminalHeaderRuLangIcon,
  terminalHeaderSelectorArrowDownIcon,
  terminalHeaderSelectorArrowUpIcon
} from '../../../../../assets/icons';
import classes from './TerminalHeader.module.scss';
import React, { FC, useEffect, useRef, useState } from 'react';
import { languageType } from '../NormalMode/NormalMode';
import { format } from 'date-fns';
import { be, ru } from 'date-fns/locale';
import { TerminalMode } from '../../../../../pagesMain/TerminalPage/TerminalPage';
import classNames from 'classnames';

type NormalHeaderType = {
  setMode: (mode: TerminalMode) => void;
  language: languageType;
  setLanguage: (newLanguage: languageType) => void;
};

const localeArray = {
  en: undefined,
  by: be,
  ru: ru
};

const languagesList = [
  {
    iconSmall: <img src={terminalHeaderRuLangIcon} width="20px" alt="RuLangIcon" />,
    iconFull: <img src={terminalHeaderRuLangIcon} width="30px" alt="RuLangIcon" />,
    shortTitle: 'ru' as languageType,
    fullTitle: 'Русский язык'
  },
  {
    iconSmall: <img src={terminalHeaderByLangIcon} width="20px" alt="ByLangIcon" />,
    iconFull: <img src={terminalHeaderByLangIcon} width="30px" alt="ByLangIcon" />,
    shortTitle: 'by' as languageType,
    fullTitle: 'Белорусский язык'
  },
  {
    iconSmall: <img src={terminalHeaderEnLangIcon} width="20px" alt="EngLangIcon" />,
    iconFull: <img src={terminalHeaderEnLangIcon} width="30px" alt="EngLangIcon" />,
    shortTitle: 'en' as languageType,
    fullTitle: 'English'
  }
];

const NormalHeader: FC<NormalHeaderType> = ({ setMode, language, setLanguage }) => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState('');
  const [selectedLang, setSelectedLang] = useState(languagesList[0].fullTitle);
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const toggleLangDropdownList = () => {
    setIsLangDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'd MMMM yyyy', { locale: localeArray[language] }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [language]);

  const handleChangeLanguage = (language: languageType): void => {
    setLanguage(language);
    setDate(format(new Date(), 'd MMMM yyyy', { locale: localeArray[language] }));
  };

  const handleClickMode = () => {
    setMode(TerminalMode.NotNormal);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsLangDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className={classes.normalHeaderContainer}>
      <div className={classes.logoDateContainer}>
        <img src={terminalHeaderLogoIcon} alt="LogoIcon" />
      </div>

      <div className={classes.settingsContainer}>
        <div className={classes.date}>
          <img src={terminalHeaderCalendarIcon} alt="CalendarIcon" />
          <span>{date}</span>
        </div>

        <div>
          <button className={classes.poorVisionIcon} onClick={() => handleClickMode()}>
            <img src={eyeGlassesIcon} alt="poorVisionIcon" />
          </button>
        </div>

        <div
          className={classNames(classes.languagesContainer, {
            [classes.languagesContainerActive]: isLangDropdownOpen
          })}>
          <button onClick={toggleLangDropdownList}>
            {languagesList.find((e) => e.fullTitle === selectedLang).iconFull ||
              languagesList[0].iconFull}
            <span>{selectedLang}</span>
            <div>
              {isLangDropdownOpen && (
                <img src={terminalHeaderSelectorArrowUpIcon} alt="SelectorArrowUpIcon" />
              )}
              {!isLangDropdownOpen && (
                <img src={terminalHeaderSelectorArrowDownIcon} alt="SelectorArrowDownIcon" />
              )}
            </div>
          </button>

          {isLangDropdownOpen && (
            <div ref={modalRef} className={classes.languagesDropdownList}>
              {languagesList.map((e, idx) => {
                if (selectedLang !== e.fullTitle) {
                  return (
                    <button
                      className={classes.langDropdownBtn}
                      onClick={() => {
                        setSelectedLang(e.fullTitle);
                        setIsLangDropdownOpen(false);
                        handleChangeLanguage(e.shortTitle);
                      }}
                      key={idx}>
                      {e.iconSmall}
                      <span>{e.shortTitle}</span>
                    </button>
                  );
                }
              })}
            </div>
          )}
        </div>

        <div className={classes.time}>
          <img src={terminalHeaderClockIcon} alt="CalendarIcon" />
          <span>{time}</span>
        </div>
      </div>
    </header>
  );
};

export default NormalHeader;
