import { useMutation, useQueryClient } from 'react-query';
import { RedirectServiceAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { AlertContext } from '../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../constants/alertText';
import { AlertType } from '../../types/enums';

export type RedirectDataType = {
  topicId: number;
  comment: string;
  serviceUnitId: number;
};

export const useRedirectToOtherEmployee = (itemId: number | null) => {
  const client = useQueryClient();
  const context = useContext(AlertContext);
  const { mutate: redirectToOtherEmployee, isLoading: isLoadingRedirectionEmployee } = useMutation(
    ['postRedirect'],
    (data: RedirectDataType) => {
      return axios.post(RedirectServiceAPI.postRedirectService(itemId), data, commonHeaders());
    },
    {
      onSuccess: () => {
        client
          .invalidateQueries('agentQueue')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { redirectToOtherEmployee, isLoadingRedirectionEmployee };
};
