import { QueryClient, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

type ActivateDeactivateDataType = {
  state: string;
};
export const useActivateDeactivateTopic = (closeMenu: (id: string | null) => void) => {
  const client: QueryClient = useQueryClient();
  const context = useContext(AlertContext);
  const { mutate: activateOrDeactivateTopic, isLoading: isLoadingTopicActivatingDeactivating } =
    useMutation(
      (requestData: { topicId: number; data: ActivateDeactivateDataType }) => {
        const { topicId, data } = requestData;
        return axios.put(
          CategoriesTopicsTableAPI.activateDeactivateTopic(topicId),
          data,
          commonHeaders()
        );
      },
      {
        onSuccess() {
          client
            .invalidateQueries('allCategoriesTopics')
            .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
            .then(() => closeMenu(null))
            .then(() =>
              context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
            );
        }
      }
    );
  return { activateOrDeactivateTopic, isLoadingTopicActivatingDeactivating };
};
