import React from 'react';
import classes from './CustomRadio.module.scss';

type CustomRadioProps = {
  id: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  disabled?: boolean;
};
const CustomRadio = ({ id, value, checked, onChange, label, disabled }: CustomRadioProps) => {
  return (
    <label className={classes.normalModeContainer}>
      {label}
      <input
        type="radio"
        checked={checked}
        name="radio"
        onChange={onChange}
        id={id}
        value={value}
        disabled={disabled}
      />
      <span className={classes.checkmark}></span>
    </label>
  );
};
export default CustomRadio;
