import { useQuery } from 'react-query';
import axios from 'axios';
import { AgentAPI } from '../../constants/API';
import { commonHeaders } from '../../constants/HeadersAPI';

export const useGetUsedTicket = (isWorkPlaceSelected: boolean) => {
  const {
    data: usedTicket,
    isFetching: isLoadingUsedTicket,
    isError: isErrorTicket,
    isSuccess: isSuccessUsedTicket
  } = useQuery(
    ['usedTicket'],
    () => axios.get(`${AgentAPI.changeTicketStatus}/refresh`, commonHeaders()),
    {
      enabled: isWorkPlaceSelected
    }
  );
  return {
    usedTicket,
    isLoadingUsedTicket,
    isErrorTicket,
    isSuccessUsedTicket
  };
};
