import { WorkingPlacesResponseDataType } from '../../../types/Admin/WorkingPlacesTypes';

export const modifyWorkingPlacesData = (arr: WorkingPlacesResponseDataType[]) => {
  return arr.map((item) => {
    const {
      description,
      id,
      isActive,
      officeNumber,
      users,
      windowNumber,
      digitalDisplayIp,
      type,
      topics
    } = item;
    return {
      id,
      workingPlace: description,
      cabinet: officeNumber || null,
      number: windowNumber || null,
      assignedEmployees: users,
      boardIp: digitalDisplayIp || '',
      assignedServices:
        topics?.map((e) => {
          return {
            id: e.id,
            nameRu: e.name
          };
        }) || [],
      type,
      isActive
    };
  });
};
