import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { adminRoutesURLs } from '../../constants/routes';
import AdvertisingPage from '../../pagesAdmin/AdvertisingPage/AdvertisingPage';
import EmployersPage from '../../pagesAdmin/EmployersPage/EmployersPage';
import WorkplacesPage from '../../pagesAdmin/WorkplacesPage/WorkplacesPage';
import UserAreaPage from '../../pagesAdmin/UserAreaPage/UserAreaPage';
import SectionServicesPage from '../../pagesAdmin/SectionServicesPage/SectionServicesPage';
import CurrentServicesStatusPage from '../../pagesAdmin/AnalyticsAndReportsPages/CurrentServicesStatusPage/CurrentServicesStatusPage';
import ServicesPage from '../../pagesAdmin/AnalyticsAndReportsPages/ServicesPage/ServicesPage';
import VisitorsPage from '../../pagesAdmin/AnalyticsAndReportsPages/VisitorsPage/VisitorsPage';
import EmployersReportsPage from '../../pagesAdmin/AnalyticsAndReportsPages/EmployersReportsPage/EmployersReportsPage';
// import Page404 from '../../pagesAdmin/Page404/Page404';

const AdminRoutes: FC = () => {
  const { sectionServices, workplaces, employers, advertising, userArea } = adminRoutesURLs;
  const { analyticsAndReportsBase, services, employersReports, visitors, currentServicesStatus } =
    adminRoutesURLs.analyticsAndReports;

  return (
    <div>
      <Routes>
        <Route path={sectionServices} element={<SectionServicesPage />} />
        <Route path={workplaces} element={<WorkplacesPage />} />
        <Route path={employers} element={<EmployersPage />} />
        <Route path={advertising} element={<AdvertisingPage />} />
        <Route path={userArea} element={<UserAreaPage />} />
        <Route path={`${analyticsAndReportsBase}/`}>
          <Route path={services} element={<ServicesPage />} />
          <Route path={employersReports} element={<EmployersReportsPage />} />
          <Route path={visitors} element={<VisitorsPage />} />
          <Route path={currentServicesStatus} element={<CurrentServicesStatusPage />} />
        </Route>
        <Route path="/*" element={null} />
      </Routes>
    </div>
  );
};

export default AdminRoutes;
