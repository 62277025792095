import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { AdvertisingAPI } from '../../../constants/API';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertContext } from '../../../context/AlertProvider';
import { AlertType } from '../../../types/enums';

export type ActivateContentType = {
  contentId: number;
  body: UpdateContentType;
};

type UpdateContentType = {
  state: string;
};

export const usePutActivateContent = () => {
  const client = useQueryClient();
  const context = useContext(AlertContext);
  const {
    mutate: activateContent,
    isLoading: isActivateContentLoading,
    isError
  } = useMutation(
    'activateContent',
    (data: ActivateContentType) =>
      axios.put(AdvertisingAPI.activateContent(data.contentId), data.body, commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AdvertisingSettings');
        queryClient
          .invalidateQueries('allAdvertisingVideos')
          .then(() => client.invalidateQueries('allAdvertisingTickers'))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { activateContent, isActivateContentLoading, isError };
};
