export const homeRoutesURLs = {
  mainPagePath: 'main/*',
  loginPagePath: '/',
  adminPagePath: 'admin/*',
  terminalPagePath: 'terminal',
  queuePagePath: 'queue'
};

export const mainRoutesURLs = {
  agent1PagePath: 'agent1'
};

export const adminRoutesURLs = {
  sectionServices: 'section-services',
  workplaces: 'workplaces',
  employers: 'employers',
  analyticsAndReports: {
    analyticsAndReportsBase: 'analytics-and-reports',
    currentServicesStatus: 'current-services-status',
    services: 'services',
    employersReports: 'employerrs-reports',
    visitors: 'visitors'
  },
  advertising: 'advertising',
  userArea: 'user-area'
};
