import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError } from 'axios';
import { AgentAPI } from '../../constants/API';
import { AlertContext } from '../../context/AlertProvider';
import { useContext } from 'react';
import { ErrorResponseDataType } from '../../types/Admin/CategoriesTopicsTypes';
import { defaultErrorText } from '../../types/Error/ErrorResponseType';
import { commonHeaders } from '../../constants/HeadersAPI';
import { AlertType } from '../../types/enums';
import { defaultSuccessMessage } from '../../constants/alertText';

type dataType = {
  ticketId: number;
  requestStatus: string;
};

export const useTicketStatus = (
  agentStatus: string,
  showTicketInWorkingModal: (value: boolean) => void
) => {
  const client = useQueryClient();
  const context = useContext(AlertContext);
  const {
    mutate,
    isLoading: isLoadingTicketStatus,
    isError: isErrorTicketStatus,
    isSuccess: isSuccessTicketStatus
  } = useMutation(
    ['ticketStatus', agentStatus],
    (data: dataType) =>
      axios.put(
        `${AgentAPI.changeTicketStatus}/${data.ticketId}`,
        { status: data.requestStatus },
        commonHeaders()
      ),
    {
      onError: (error: AxiosError<ErrorResponseDataType>) => {
        if (error instanceof AxiosError && error.response?.data.errorStatus) {
          context?.setAlert({ type: AlertType.Error, message: error.response?.data.message });
          if (error.response?.data?.errorStatus === 409) {
            showTicketInWorkingModal(true);
          }
        } else {
          context?.setAlert({ type: AlertType.Error, message: defaultErrorText });
        }
      },
      onSuccess() {
        client
          .invalidateQueries('agentQueue')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { mutate, isLoadingTicketStatus, isErrorTicketStatus, isSuccessTicketStatus };
};
