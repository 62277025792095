import { useContext } from 'react';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AlertType } from '../../../types/enums';
import { WorkingPlacesTableAPI } from '../../../constants/API';
import { AlertContext } from '../../../context/AlertProvider';
import { defaultSuccessMessage } from '../../../constants/alertText';

export const useDeleteWorkingPlace = (
  setWorkingPlaceDeleteModalActive: (status: boolean) => void
) => {
  const context = useContext(AlertContext);
  const { mutate: deleteWorkingPlace, isLoading: isDeleteWorkingPlaceLoading } = useMutation(
    'deleteWorkingPlace',
    (workingPlaceId: number) =>
      axios.delete(WorkingPlacesTableAPI.deleteWorkingPlace(workingPlaceId), commonHeaders()),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries('workingPlaces')
          .then(() => setWorkingPlaceDeleteModalActive(false))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { deleteWorkingPlace, isDeleteWorkingPlaceLoading };
};
