import { QueryClient, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { useContext } from 'react';
import { AlertContext } from '../../../context/AlertProvider';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

export type topicDataType = {
  nameRu: string;
  nameBe: string;
  nameEn: string;
  itemNamePrefix: string;
  startWork: string;
  endWork: string;
};
export const useCreateTopic = (closeCreateTopicModal: () => void) => {
  const client: QueryClient = useQueryClient();
  const context = useContext(AlertContext);
  const { mutate: createTopic, isLoading: isLoadingCreatedTopic } = useMutation(
    (data: topicDataType) => {
      return axios.post(CategoriesTopicsTableAPI.postCreateTopic, data, commonHeaders());
    },
    {
      onSuccess() {
        client
          .invalidateQueries('allCategoriesTopics')
          .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
          .then(() => closeCreateTopicModal())
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { createTopic, isLoadingCreatedTopic };
};
