import React, { FC, useEffect } from 'react';
import { useState } from 'react';

import styles from './Tabs.module.scss';

type TabsProps = {
  onHandleClick?: (text: string) => void;
  tabs?: { data: string; tabText: string }[];
  activatedTab?: string;
};

const Tabs: FC<TabsProps> = ({ onHandleClick, tabs, activatedTab }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].data);

  useEffect(() => {
    onHandleClick(activeTab);
  }, [activeTab]);

  const handleButtonClick = (content: string) => {
    setActiveTab(content);
    onHandleClick(content);
  };

  useEffect(() => {
    if (activatedTab) {
      handleButtonClick(activatedTab);
    }
  }, [activatedTab]);

  return (
    <div className={styles.tabs}>
      {tabs &&
        tabs.map(({ data, tabText }) => (
          <div
            key={data}
            className={activeTab === data ? `${styles.tab} ${styles.active}` : styles.tab}
            onClick={() => handleButtonClick(data)}>
            {tabText}
          </div>
        ))}
    </div>
  );
};

export default Tabs;
