import React, { useState, FC } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import classes from './ModalWorkplace.module.scss';
import { useGetWorkplacesList } from '../../../../hooks/MainLayout/useGetWokplacesList';
import { usePutUserAuthInfo } from '../../../../hooks/MainLayout/usePutUserAuthInfo';

type ModalWorkplaceProps = {
  isActive: boolean;
  setActiveModal?: (value: boolean) => void;
  setWorkPlaceSelected: (value: boolean) => void;
};

const customStyles = {
  control: (base) => ({
    ...base,
    height: 60,
    minHieght: 60,
    widht: 680,
    minWidth: 680,
    textTransform: 'uppercase',
    cursor: 'pointer'
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'white',
    color: 'black',
    textTransform: 'uppercase',
    cursor: 'pointer'
  })
};

const ModalWorkplace: FC<ModalWorkplaceProps> = ({
  isActive,
  setActiveModal,
  setWorkPlaceSelected
}) => {
  const [selectValue, setSelectValue] = useState(null);

  const { userWorkplacesResp, isUserWorkplacesRespRecived } = useGetWorkplacesList();
  const userWorkplaces = isUserWorkplacesRespRecived ? userWorkplacesResp?.data : [];
  const { putUserAuthInfo, isLoadingPutUserAuthInfo } = usePutUserAuthInfo(
    setWorkPlaceSelected,
    setActiveModal
  );

  const userWorkplacesOptions = userWorkplaces?.map((item) => {
    return { ...item, label: item.serviceUnitName };
  });

  const handleSubmit = () => {
    const data = { serviceUnitId: selectValue.serviceUnitId };
    putUserAuthInfo(data);
  };

  return (
    <div
      className={classNames(
        classes.modal,
        { [classes.active]: isActive },
        { [classes.load]: isLoadingPutUserAuthInfo }
      )}>
      <div className={classes.modalContainer}>
        <div className={classes.title}>Выберите рабочее место</div>
        <div className={classes.selectContainer}>
          <ReactSelect
            placeholder={''}
            noOptionsMessage={() => 'Выберите рабочее место'}
            options={userWorkplacesOptions}
            styles={customStyles}
            onChange={(value) => setSelectValue(value)}
          />
        </div>
        <div className={classes.btnContainer}>
          <button
            className={classes.btn}
            onClick={() => handleSubmit()}
            disabled={!selectValue || isLoadingPutUserAuthInfo}>
            готово
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalWorkplace;
