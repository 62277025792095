import * as yup from 'yup';

const AuthorizationSchema = yup
  .object({
    username: yup.string().required('Неверный ID пользователя, попробуйте еще раз'),
    password: yup.string().required('Неверный пароль, попробуйте еще раз')
  })
  .required();

export default AuthorizationSchema;
