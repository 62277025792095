import { FC, useContext, useEffect } from 'react';
import { AlertContext } from '../../context/AlertProvider';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { AlertType } from '../../types/enums';
import { defaultErrorText } from '../../types/Error/ErrorResponseType';
import { homeRoutesURLs } from '../../constants/routes';

type AxiosInterceptorProps = {
  setIsAuthenticated: (status: boolean) => void;
};

const AxiosInterceptor: FC<AxiosInterceptorProps> = ({ setIsAuthenticated }) => {
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error instanceof AxiosError) {
          setAlert({ type: AlertType.Error, message: error.response?.data.message });
        } else {
          setAlert({ type: AlertType.Error, message: defaultErrorText });
        }
        if (401 === error.response.status) {
          setIsAuthenticated(false);
          navigate(homeRoutesURLs.loginPagePath);
          localStorage.removeItem('user');
          return;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, []);

  return null;
};

export default AxiosInterceptor;
