import React, { FC, useEffect, useState } from 'react';
import AdvertisingTable from '../../components/Admin/Tables/AdvertisingTable/AdvertisingSettings/AdvertisingSettingsTable';
import ContentHeader from '../../components/Admin/ContentHeader';
import classes from './AdvertisingPage.module.scss';
import { ModalTableVideo } from '../../components/Admin/Modals/ModalsAdvertising/ModalTableVideo/ModalTableVideo';
import { ModalTickerBoard } from '../../components/Admin/Modals/ModalsAdvertising/ModalTickerBoard/ModalTickerBoard';
import VideoGalleryTable from '../../components/Admin/Tables/AdvertisingTable/VideoGalleryTable/VideoGalleryTable';
import TickersTable, {
  TickersDataTableType
} from '../../components/Admin/Tables/AdvertisingTable/RunningQweryTable/TickersTable';
import { ModalEditPlaylist } from '../../components/Admin/Modals/ModalsAdvertising/ModalEditPlaylist/ModalEditPlaylist';
import { ModalTableText } from '../../components/Admin/Modals/ModalsAdvertising/ModalTableText/ModalTableText';
import { useGetAdvertisingSettings } from '../../hooks/Admin/Advertising/useGetSettings';
import { usePostVideo } from '../../hooks/Admin/Advertising/usePostVideo';
import { useGetAllAdvertisingVideos } from '../../hooks/Admin/Advertising/useGetAllVideos';
import { usePutActivateContent } from '../../hooks/Admin/Advertising/usePutActivateContent';
import { ModalMode } from '../../types/enums';
import { useGetAdvertisingTickers } from '../../hooks/Admin/Advertising/useGetAllTickers';
import { usePostTicker } from '../../hooks/Admin/Advertising/usePostTicker';
import { usePutTicker } from '../../hooks/Admin/Advertising/usePutTicker';
import { usePutHeaders } from '../../hooks/Admin/Advertising/usePutHeaders';
import { usePutPlaylist } from '../../hooks/Admin/Advertising/usePutPlaylist';
import { useDeleteTicker } from '../../hooks/Admin/Advertising/useDeleteTicker';
import { useDeleteVideo } from '../../hooks/Admin/Advertising/useDeleteVideo';

export type TickerType = {
  contentId: number;
  type: string;
  isActive: boolean;
  info: {
    text: string;
  };
};

export type ContentType = {
  id?: number;
  title: string;
};

export type HeadersType = {
  title: string;
  subTitle: string;
};

type SettingsItemType = {
  id: number;
  elementTitle: string;
  currentData: ContentType[];
  isActive: boolean;
};

export enum AdvertisingElements {
  VideoPlaylist = 'Табло видео',
  TickersPlaylist = 'Бегущая строка',
  Headers = 'Заголовки'
}

const tableName = 'Реклама';
const tabs = [
  { data: 'settings', tabText: 'Настройки' },
  { data: 'videosGallery', tabText: 'Видео' },
  { data: 'tickersGallery', tabText: 'Бегущая строка' }
];

const AdvertisingPage: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].data);
  const [editTickerModalActive, setEditTickerModalActive] = useState(false);
  const [editTickerModalMode, setEditTickerModalMode] = useState<string>(ModalMode.Create);

  const [isModalEditPlaylistActive, setIsModalEditPlaylistActive] = useState(false);
  const [isModalEditHeadersActive, setIsModalEditHeadersActive] = useState(false);
  const [modalVideoDownloadActive, setModalVideoDownloadActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);

  const [activeTickerRowData, setActiveTickerRowData] = useState<TickersDataTableType>(null);
  const [settingsData, setSettingsData] = useState<SettingsItemType[]>();
  const [tickersData, setTickersData] = useState<ContentType[]>();
  const [playlistData, setPlaylistData] = useState<ContentType[]>();
  const [headersData, setHeadersData] = useState<HeadersType>();
  const [allSystemVideos, setAllSystemVideos] = useState<ContentType[]>();

  // Hooks
  const { advertisingSettingsResp, isLoadingAdvertisingSettings } = useGetAdvertisingSettings(
    setPlaylistData,
    setTickersData,
    setHeadersData
  );
  const { advertisingVideosResp, isLoadingAdvertisingVideos } =
    useGetAllAdvertisingVideos(setAllSystemVideos);
  const { advertisingTickersResp, isLoadingAdvertisingTickers } = useGetAdvertisingTickers();
  const { createNewVideo, isCreateNewVideoLoading } = usePostVideo(setModalVideoDownloadActive);
  const { createNewTicker, isCreateNewTickerLoading } = usePostTicker(setEditTickerModalActive);
  const { editTicker, isEditTickerLoading } = usePutTicker(setEditTickerModalActive);
  const { editHeaders, isEditHeadersLoading } = usePutHeaders(setIsModalEditHeadersActive);
  const { editPlaylist, isEditPlaylistLoading } = usePutPlaylist(setIsModalEditPlaylistActive);
  const { deleteTicker, isDeleteTickerLoading } = useDeleteTicker(setDeleteModalActive);
  const { deleteVideo, isDeleteVideoLoading } = useDeleteVideo(setDeleteModalActive);
  const { activateContent } = usePutActivateContent();

  useEffect(() => {
    setSettingsData([
      {
        id: 0,
        elementTitle: AdvertisingElements.VideoPlaylist,
        currentData: playlistData,
        isActive: true
      },
      {
        id: 1,
        elementTitle: AdvertisingElements.TickersPlaylist,
        currentData: tickersData,
        isActive: true
      },
      {
        id: 2,
        elementTitle: AdvertisingElements.Headers,
        currentData: headersData
          ? Object.values(headersData).map((e) => ({ title: e }))
          : [{ title: '' }],
        isActive: true
      }
    ]);
  }, [advertisingSettingsResp]);

  const handleOpenCreateTickerModal = (): void => {
    setEditTickerModalActive(true);
  };

  return (
    <div className={classes.container}>
      <ContentHeader
        setModalVideoDownloadActive={
          activeTab === tabs[1].data ? setModalVideoDownloadActive : null
        }
        setModalMode={activeTab === tabs[2].data ? setEditTickerModalMode : null}
        handleButtonClick={activeTab === tabs[2].data ? handleOpenCreateTickerModal : null}
        handleClickTabs={setActiveTab}
        tabs={tabs}
        name={tableName}
      />
      {modalVideoDownloadActive && (
        <ModalTableVideo
          isLoading={isCreateNewVideoLoading}
          modalVideoDownloadActive={modalVideoDownloadActive}
          setModalVideoDownloadActive={setModalVideoDownloadActive}
          createNewVideo={createNewVideo}
        />
      )}
      {editTickerModalActive && (
        <ModalTickerBoard
          mode={editTickerModalMode}
          createNewTicker={createNewTicker}
          editTicker={editTicker}
          isLoading={
            editTickerModalMode === ModalMode.Create
              ? isCreateNewTickerLoading
              : isEditTickerLoading
          }
          editTickerModalActive={editTickerModalActive}
          setEditTickerModalActive={setEditTickerModalActive}
          data={activeTickerRowData}
        />
      )}
      {isModalEditPlaylistActive && allSystemVideos && playlistData && (
        <ModalEditPlaylist
          isModalEditPlaylistActive={isModalEditPlaylistActive}
          setIsModalEditPlaylistActive={setIsModalEditPlaylistActive}
          allSystemElements={allSystemVideos}
          assignedElements={playlistData}
          editPlaylist={editPlaylist}
          isLoadingEditPlaylist={isEditPlaylistLoading}
        />
      )}
      {isModalEditHeadersActive && headersData && (
        <ModalTableText
          isActive={isModalEditHeadersActive}
          setActive={setIsModalEditHeadersActive}
          editHeaders={editHeaders}
          isLoading={isEditHeadersLoading}
          data={headersData}
        />
      )}

      {activeTab === tabs[0].data && advertisingSettingsResp && (
        <AdvertisingTable
          setIsModalEditPlaylistActive={setIsModalEditPlaylistActive}
          setIsModalEditHeadersActive={setIsModalEditHeadersActive}
          advertisingSettingsData={settingsData}
          isLoadingData={isLoadingAdvertisingSettings}
        />
      )}
      {activeTab === tabs[1].data && advertisingVideosResp && (
        <VideoGalleryTable
          deleteVideo={deleteVideo}
          setDeleteModalActive={setDeleteModalActive}
          deleteModalActive={deleteModalActive}
          videoGalleryData={advertisingVideosResp.data.video}
          activateContent={activateContent}
          isLoadingData={isLoadingAdvertisingVideos}
          isLoadingDeleteVideo={isDeleteVideoLoading}
        />
      )}
      {activeTab === tabs[2].data && advertisingTickersResp?.tickers && (
        <TickersTable
          deleteTicker={deleteTicker}
          setDeleteModalActive={setDeleteModalActive}
          deleteModalActive={deleteModalActive}
          setActiveQweryRowData={setActiveTickerRowData}
          tickersGalleryData={advertisingTickersResp.tickers}
          setEditTickerModalActive={setEditTickerModalActive}
          setEditTickerModalMode={setEditTickerModalMode}
          activateContent={activateContent}
          isLoadingData={isLoadingAdvertisingTickers}
          isLoadingDeleteTicker={isDeleteTickerLoading}
        />
      )}
    </div>
  );
};

export default AdvertisingPage;
