import { MonitorUnitType } from '../../components/Main/QueuePanel/QueuePanel';

export const transformReceivedSounds = (soundListArr: MonitorUnitType[]) => {
  return {
    sounds: soundListArr
      .map((e) => {
        if (e && !e.isSoundPlayed && e.audioList.length) {
          return e.audioList.filter((audio) => typeof audio === 'string');
        } else {
          return [];
        }
      })
      .filter((e) => e.length),
    receivedSoundsIds: soundListArr
      .map((e) => {
        if (e && !e.isSoundPlayed && e.audioList.length) {
          return e.itemId;
        } else {
          return;
        }
      })
      .filter((e) => e !== undefined)
  };
};
