import React from 'react';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { homeRoutesURLs } from '../../constants/routes';

type Props = {
  isAuthenticated: boolean;
};

export const ProtectedRoute: FC<Props> = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to={homeRoutesURLs.loginPagePath} replace />;
  }

  return <Outlet />;
};
