import React from 'react';
import classes from './InvalidLicenseMessage.module.scss';

type InvalidLicenseMessageProps = {
  daysLeft: number;
};

const InvalidLicenseMessage = ({ daysLeft }: InvalidLicenseMessageProps) => {
  return (
    <>
      {daysLeft && (
        <div className={classes.LicenseMessageWrapper}>
          <div>
            <span>Срок действия вашей лицензии истекает.</span>
          </div>
          {daysLeft === 1 && (
            <div>
              <span>ОСТАЛСЯ {daysLeft} ДЕНЬ</span>
            </div>
          )}
          {daysLeft > 1 && daysLeft < 5 && (
            <div>
              <span>ОСТАЛОСЬ {daysLeft} ДНЯ</span>
            </div>
          )}
          {daysLeft > 4 && (
            <div>
              <span>ОСТАЛОСЬ {daysLeft} дней</span>
            </div>
          )}
        </div>
      )}
      {daysLeft <= 0 && (
        <div className={classes.LicenseMessageWrapper}>
          <div>
            <span>Срок действия вашей лицензии истек!</span>
          </div>
        </div>
      )}
    </>
  );
};

export default InvalidLicenseMessage;
