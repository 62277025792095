import { useQuery } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { WorkingPlacesTableAPI } from '../../../constants/API';

type RespType = {
  users: UsersRolesType[];
};

export type UsersRolesType = {
  id: number;
  name: string;
  role: string;
};

export const useGetAllRoles = () => {
  const {
    data: rolesResp,
    isFetching: isLoadingRoles,
    isSuccess: isRolesRecieved
  } = useQuery(
    ['roles'],
    () => axios.get<RespType>(WorkingPlacesTableAPI.getRolesAndEmplouees, commonHeaders()),
    {
      select: (data) => data.data.users
    }
  );

  return { rolesResp, isLoadingRoles, isRolesRecieved };
};
