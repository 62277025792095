import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';
import { AnalyticsAndReportsDateType } from '../../../../types/Admin/AnalyticsAndReports';

type ServiceReportType = {
  date: string;
  topic: string;
  registeredClients: string;
  clientsCalledFromQueue: string;
  clientsDone: string;
  clientsRedirect: string;
  clientsNotCome: string;
  serviceAvgTime: string;
};

export const useGetServiceReport = (date: AnalyticsAndReportsDateType) => {
  const {
    data: serviceReportResp,
    isFetching: isLoadingServiceReport,
    isSuccess: isServiceReportRecieved,
    refetch: refetchServiceReport
  } = useQuery(
    ['serviceReport'],
    () =>
      axios.get<ServiceReportType[]>(
        `${AdminAnalyticsAndReportsAPI.getServicesReport}?startDate=${date.startDate}&endDate=${date.endDate}`,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e) => {
            return {
              date: e.date,
              serviceName: e.topic,
              registredServices: e.registeredClients,
              calledFromQueue: e.clientsCalledFromQueue,
              served: e.clientsDone,
              redirected: e.clientsRedirect,
              missed: e.clientsNotCome,
              averageServiceTime: e.serviceAvgTime
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    serviceReportResp,
    isLoadingServiceReport,
    isServiceReportRecieved,
    refetchServiceReport
  };
};
