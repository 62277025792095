import { useContext } from 'react';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AlertType } from '../../../types/enums';
import { AdminAPI } from '../../../constants/API';
import { AlertContext } from '../../../context/AlertProvider';
import { defaultSuccessMessage } from '../../../constants/alertText';

export const useDeleteUser = (setUserDeleteModalActive: (status: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: deleteUser,
    isLoading: isDeleteUserLoading,
    isError
  } = useMutation(
    'deletedUserStatus',
    (userId: number) => axios.delete(AdminAPI.deleteUser(userId), commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('allEmployeesData');
        queryClient
          .invalidateQueries('allSystemTopics')
          .then(() => setUserDeleteModalActive(false))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { deleteUser, isDeleteUserLoading, isError };
};
