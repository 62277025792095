import { useMutation } from 'react-query';
import { AuthorizationAPI } from '../../constants/API';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { setUserRole } from '../../utils/Authorization/authorization';

export type UserType = {
  login: string;
  password: string;
};

export const useLogin = (setIsAuthenticated: (status: boolean) => void) => {
  const navigate = useNavigate();
  const {
    mutate: login,
    isLoading,
    isError
  } = useMutation(
    ['login'],
    async (data: UserType) =>
      axios.post(AuthorizationAPI.logIn, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }),
    {
      onSuccess(res) {
        setIsAuthenticated(true);
        setUserRole(res.data, navigate);
      }
    }
  );

  return { login, isLoading, isError };
};
