import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import classes from './PoorVisionTerminalHeader.module.scss';
import { TerminalMode } from '../../../../../pagesMain/TerminalPage/TerminalPage';
import { format } from 'date-fns';
import { be, ru } from 'date-fns/locale';
import { languageType } from '../../Normal/NormalMode/NormalMode';
import {
  eyeGlassesPoorVisionIcon,
  eyeGlassesWhiteIcon,
  skyBlackLogo,
  skyWhiteLogo,
  tehnoBlackLogo,
  tehnoWhiteLogo
} from '../../../../../assets/icons';
import { ButtonsEditLanguage } from '../ButtonsEditLanguage/ButtonsEditLanguage';
import { ThemeColors } from '../../../../../types/enums';

type PoorVisionTerminalHeaderType = {
  themeColor: string;
  setMode: (mode: TerminalMode) => void;
  language: languageType;
  setLanguage: (newLanguage: languageType) => void;
};
const localeArray = {
  en: undefined,
  by: be,
  ru: ru
};
const PoorVisionTerminalHeader: FC<PoorVisionTerminalHeaderType> = ({
  setMode,
  themeColor,
  language,
  setLanguage
}) => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState<string>();

  const bgThemeColor = classNames({
    [classes.bgBlue]: themeColor === ThemeColors.Blue,
    [classes.bgWhite]: themeColor === ThemeColors.White,
    [classes.bgBlack]: themeColor === ThemeColors.Black
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now: Date = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'd MMMM', { locale: localeArray[language] }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [language]);
  const handleClickMode = () => {
    setMode(TerminalMode.Normal);
  };

  const handleChangeLanguage = (language: languageType): void => {
    setLanguage(language);
    setDate(format(new Date(), 'd MMMM', { locale: localeArray[language] }));
  };

  return (
    <div className={classes.headerContainer}>
      <header className={classNames(bgThemeColor, classes.poorVisionHeader)}>
        <div className={classes.leftHeaderPart}>
          <div className={classes.logoContainer}>
            <div>
              {themeColor === ThemeColors.Black ? (
                <>
                  <img src={tehnoBlackLogo} alt="logo" className={classes.whiteTechnoLogo} />
                  <img src={skyWhiteLogo} alt="logo" className={classes.whiteSkyLogo} />
                </>
              ) : (
                <div>
                  <img src={tehnoWhiteLogo} alt="logo" className={classes.blackTechnoLogo} />
                  <img src={skyBlackLogo} alt="logo" className={classes.blackSkyLogo} />
                </div>
              )}
            </div>
          </div>
          <div className={classes.dateTimeContainer}>
            <div className={classes.date}>
              <span id={classNames({ [classes.whiteDate]: themeColor === ThemeColors.Black })}>
                {date}
              </span>
            </div>
            <div className={classes.time}>
              <span id={classNames({ [classes.whiteDate]: themeColor === ThemeColors.Black })}>
                {time}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.rightHeaderPart}>
          <button className={classes.glassesIcon} onClick={() => handleClickMode()}>
            <img
              src={
                themeColor === ThemeColors.Black ? eyeGlassesWhiteIcon : eyeGlassesPoorVisionIcon
              }
              alt="userIcon"
              className={bgThemeColor}
            />
          </button>
          <ButtonsEditLanguage
            language={language}
            handleChangeLanguage={handleChangeLanguage}
            theme={themeColor}
          />
        </div>
      </header>
      <div
        className={classes.headerLine}
        id={classNames({ [classes.whiteLine]: themeColor === ThemeColors.Black })}></div>
    </div>
  );
};

export default PoorVisionTerminalHeader;
