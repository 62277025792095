import classes from './DropDown.module.scss';
import { arrowUp, rowDownArr } from '../../../assets/icons/index';
import * as React from 'react';
import classNames from 'classnames';

type DropDownProps = {
  isMenuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  label: string;
  activeService: string;
  children: React.ReactNode;
};

export const DropDown = ({
  isMenuOpen,
  setMenuOpen,
  label,
  activeService,
  children
}: DropDownProps) => {
  const handleMenuToggle = (): void => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className={classes.dropdown}>
      <div
        className={classNames(classes.controller, { [classes.open]: isMenuOpen })}
        onClick={handleMenuToggle}>
        <span>{activeService}</span>
        <label className={classes.services}>{label}</label>
        <div className={classes.image}>
          {isMenuOpen ? (
            <img src={arrowUp} alt="Row Up" />
          ) : (
            <img src={rowDownArr} className={classes.arrowDown} alt="Row Down" />
          )}
        </div>
      </div>
      {isMenuOpen && <ul className={classes.menu}>{children}</ul>}
    </div>
  );
};
