import { QueryClient, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

export const useDeleteTopicById = (closeMenu: (value: boolean) => void) => {
  const client: QueryClient = useQueryClient();
  const context = useContext(AlertContext);
  const { mutate: deleteTopic, isLoading: isLoadingTopicDelete } = useMutation(
    (rowId: number) => axios.delete(CategoriesTopicsTableAPI.deleteTopic(rowId), commonHeaders()),
    {
      onSuccess: () => {
        client
          .invalidateQueries('allCategoriesTopics')
          .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
          .then(() => closeMenu(false))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { deleteTopic, isLoadingTopicDelete };
};
