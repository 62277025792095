import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';
import { checkIcon, modifierIcon } from '../../../../assets/icons';
// import { rowData2 } from './data';
import classes from './EmployeesTable.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { handleClickOutside } from '../../../../utils/Admin/handleClickOutside';
import {
  AllEmpoyeesDataType,
  EmployerType
} from '../../../../pagesAdmin/EmployersPage/EmployersPage';
import { UserStatusInfoType } from '../../../../hooks/Admin/EmployeesTable/usePutUserStatus';
import { ModalUserDelete } from '../../Modals/ModalDelete/ModalUserDelete';
import { ActivateUser } from '../../../../types/enums';
import { ActionMenu } from '../../ActionMenu/ActionMenuGeneral/ActionMenu';
import Skeleton from '../../Skeleton/Skeleton';

const columnSizes = {
  employeeWidth: 405,
  accessLevelWidth: 167,
  isActiveWidth: 94
};

type EmployeesTableProps = {
  setModalActive: (value: boolean) => void;
  allEmpoyeesData: AllEmpoyeesDataType;
  activeRowDataEmployer: EmployerType;
  setActiveRowDataEmployer: (user: EmployerType) => void;
  setModalMode: (mode: string) => void;
  updateUserStatus: (userData: UserStatusInfoType) => void;
  deleteUser: (userId: number) => void;
  userDeleteModalActive: boolean;
  setUserDeleteModalActive: (status: boolean) => void;
  isDeleteUserLoading: boolean;
  isLoadingData: boolean;
};

const { employeeWidth, accessLevelWidth, isActiveWidth } = columnSizes;
const columnHelper = createColumnHelper<EmployerType>();

const EmployeesTable: FC<EmployeesTableProps> = ({
  setModalActive,
  allEmpoyeesData,
  setModalMode,
  activeRowDataEmployer,
  setActiveRowDataEmployer,
  deleteUser,
  updateUserStatus,
  isDeleteUserLoading,
  userDeleteModalActive,
  setUserDeleteModalActive,
  isLoadingData
}) => {
  // const [data] = useState(allEmpoyeesData ? [...allEmpoyeesData.employeesTableData] : []);
  const [activeRow, setActiveRow] = React.useState(null);

  const [openMenuId, setOpenMenuId] = useState(null);
  const menuElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) =>
      handleClickOutside(event, menuElement, setOpenMenuId)
    );
    return () => {
      document.removeEventListener('mousedown', (event: MouseEvent) =>
        handleClickOutside(event, menuElement, setOpenMenuId)
      );
    };
  }, [setOpenMenuId]);

  const handleButtonClickAccess = () => {
    setModalActive(true);
    setOpenMenuId(null);
  };

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const EmployeesTableColumns = [
    columnHelper.accessor('employee', {
      cell: ({ getValue, row }) => (
        <div className={classes.cellEmployees}>
          <div className={classes.editMenuContainer}>
            <img
              id={'imgMenu'}
              src={modifierIcon}
              alt="modifierService"
              className={classes.modifierImage}
              onClick={() => {
                if (row?.original?.id && !row?.original?.isReadOnly) {
                  if (openMenuId === row.original.id) {
                    setOpenMenuId(null);
                  } else {
                    setOpenMenuId(row.original.id);
                  }
                  setActiveRowDataEmployer(row.original);
                  setModalMode('edit');
                } else {
                  return;
                }
              }}
            />
            {openMenuId === row?.original?.id && (
              <div ref={menuElement} className={classes.actonMenuContainer}>
                <ActionMenu
                  editOnClick={handleButtonClickAccess}
                  isActivate={row.original.isActive}
                  activateDeactivateClick={() => {
                    updateUserStatus({
                      userId: row.original.id,
                      userStatus: row.original.isActive
                        ? { state: ActivateUser.Deactivate }
                        : { state: ActivateUser.Activate }
                    });
                    setOpenMenuId(null);
                  }}
                  deleteOnClick={() => {
                    setUserDeleteModalActive(true);
                    setOpenMenuId(null);
                  }}
                />
              </div>
            )}
          </div>
          <div className={classes.employees}>{getValue()}</div>
        </div>
      ),
      header: () => <div className={classes.headerEmployees}>Сотрудник</div>,
      size: employeeWidth
    }),
    columnHelper.accessor('accessLevel', {
      cell: ({ getValue }) => <div className={classes.contentCenter}>{getValue()}</div>,
      header: () => <div className={classes.contentCenter}>Уровень доступа</div>,
      size: accessLevelWidth
    }),
    columnHelper.accessor('isActive', {
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.cellIsActive}>
            <img src={checkIcon as unknown as string} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      header: () => <div className={classes.contentCenter}>Активный</div>,
      size: isActiveWidth
    })
  ];

  const columns = EmployeesTableColumns;

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : allEmpoyeesData?.employeesTableData || []),
    [isLoadingData, allEmpoyeesData?.employeesTableData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.container}>
      {userDeleteModalActive && (
        <ModalUserDelete
          isActive={userDeleteModalActive}
          setActive={setUserDeleteModalActive}
          userId={activeRowDataEmployer.id}
          deleteUser={deleteUser}
          isDeleteUserLoading={isDeleteUserLoading}
        />
      )}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => handleClick(row.id)}
                className={activeRow === row.id ? classes.activeRow : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeesTable;
