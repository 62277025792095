import { QueryClient, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AlertContext } from '../../../context/AlertProvider';
import { useContext } from 'react';
import { defaultSuccessMessage } from '../../../constants/alertText';
import { AlertType } from '../../../types/enums';

type categoryDataType = {
  nameRu: string;
  nameBe: string;
  nameEn: string;
  isParentCategory: boolean;
  parentCategoryId?: number;
  topics?: number[] | [];
  startWork: string;
  endWork: string;
};
export const useCreateCategory = (closeCreateCategoryModal: () => void) => {
  const client: QueryClient = useQueryClient();
  const context = useContext(AlertContext);
  const { mutate: createCategory, isLoading: isLoadingCreatedCategory } = useMutation(
    (data: categoryDataType) => {
      return axios.post(CategoriesTopicsTableAPI.postCreateCategory, data, commonHeaders());
    },
    {
      onSuccess() {
        client
          .invalidateQueries('allCategoriesTopics')
          .then(() => client.invalidateQueries('categoriesAndTopicsForModal'))
          .then(() => closeCreateCategoryModal())
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { createCategory, isLoadingCreatedCategory };
};
