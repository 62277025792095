import React, { FC, useEffect, useState } from 'react';
import classes from './ServicesPage.module.scss';
import ServicesReportTable from '../../../components/Admin/Tables/AnalyticsAndReports/ServicesReportTable/ServicesReportTable';
import HeaderAnalyticsTables from '../../../components/Admin/HeaderAnalyticsAndReports/HeaderAnalyticsTables';
import { useGetServiceReport } from '../../../hooks/Admin/AnalyticsAndReportsTables/ServiceReport/useGetServiceReport';
import { currentDate } from '../../../utils/Admin/AnalyticsAndReports/formatAnalyticAndReportsDate';
import { saveAsCSV } from '../../../utils/Admin/AnalyticsAndReports/saveAsCSV';
import { ServicesHeader } from '../../../types/enums';

const tabsList = ['Период'];
const isActiveButtonCSV = true;

const ServicesPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabsList[0]);
  const [selectedStartDate, setSelectedStartDate] = useState(currentDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const selectedDate = {
    startDate: selectedStartDate,
    endDate: selectedEndDate
  };
  const {
    serviceReportResp: serviceReportData,
    refetchServiceReport,
    isLoadingServiceReport
  } = useGetServiceReport(selectedDate);

  const handleSaveAsCSV = () => saveAsCSV(serviceReportData, ServicesHeader);

  useEffect(() => {
    refetchServiceReport();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.container}>
      <HeaderAnalyticsTables
        tabs={tabsList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSaveAsCSV={handleSaveAsCSV}
        isActiveButtonCSV={isActiveButtonCSV}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        name="Отчет по услугам"
      />

      <ServicesReportTable
        serviceReportData={serviceReportData}
        isLoadingData={isLoadingServiceReport}
      />
    </div>
  );
};

export default ServicesPage;
