import React, { FC, useState } from 'react';
import ContentHeader from '../../components/Admin/ContentHeader';
import EmployeesTable from '../../components/Admin/Tables/EmployeesTable/EmployeesTable';
import classes from './EmployeesPage.module.scss';
import { ModalEmployees } from '../../components/Admin/Modals/ModalsEmployees/ModalEmployees/ModalEmployees';
import { useGetAllEmployees } from '../../hooks/Admin/EmployeesTable/useGetAllEmployees';
import { usePostNewEmployer } from '../../hooks/Admin/EmployeesTable/usePostNewEmployer';
import { usePutUserData } from '../../hooks/Admin/EmployeesTable/usePutUserData';
import { useDeleteUser } from '../../hooks/Admin/EmployeesTable/useDeleteUser';
import { usePutUserStatus } from '../../hooks/Admin/EmployeesTable/usePutUserStatus';

const tableName = 'Сотрудники';

export type AllEmpoyeesDataType = {
  employeesTableData: EmployerType[];
};

export type EmployerType = {
  id: number;
  employee: string;
  accessLevel: string;
  isActive: boolean;
  isReadOnly: boolean;
  roleId?: number;
};

export type AccessLevelType = {
  id: number;
  roleId?: number;
  accessLevel: string;
  assignedServices: AssignedServicesType[];
};

export type AssignedServicesType = {
  id: number;
  nameRu: string;
};

const EmployersPage: FC = () => {
  const [modalEmployeesActive, setModalEmployeesActive] = useState(false);

  const [activeRowDataEmployer, setActiveRowDataEmployer] = useState<EmployerType>(null);

  const [allEmpoyeesData, setAllEmpoyeesData] = useState<AllEmpoyeesDataType>();
  const { isLoadingAllEmployeesData } = useGetAllEmployees(setAllEmpoyeesData);
  const [modalMode, setModalMode] = useState('');
  const { createNewEmployer, isCreateNewEmployerLoading } =
    usePostNewEmployer(setModalEmployeesActive);
  const { updateUserData, isUpdateUserDataLoading } = usePutUserData(setModalEmployeesActive);
  const [userDeleteModalActive, setUserDeleteModalActive] = useState(false);
  const { deleteUser, isDeleteUserLoading } = useDeleteUser(setUserDeleteModalActive);
  const { updateUserStatus } = usePutUserStatus();

  const handleActiveModalOnCreateBtn = (): void => {
    setModalEmployeesActive(true);
  };

  // if (isLoadingAllEmployeesData) return <div>Loading...</div>;

  return (
    <div className={classes.container}>
      <>
        <ContentHeader
          name={tableName}
          setModalMode={setModalMode}
          handleButtonClick={handleActiveModalOnCreateBtn}
        />
        {modalEmployeesActive && (
          <ModalEmployees
            isActive={modalEmployeesActive}
            setActive={setModalEmployeesActive}
            activeRowDataEmployer={activeRowDataEmployer}
            createNewEmployer={createNewEmployer}
            updateUserData={updateUserData}
            modalMode={modalMode}
            isLoadingCreate={isCreateNewEmployerLoading}
            isLoadingUpdate={isUpdateUserDataLoading}
          />
        )}

        {allEmpoyeesData && (
          <EmployeesTable
            allEmpoyeesData={allEmpoyeesData}
            setModalActive={setModalEmployeesActive}
            activeRowDataEmployer={activeRowDataEmployer}
            setActiveRowDataEmployer={setActiveRowDataEmployer}
            setModalMode={setModalMode}
            deleteUser={deleteUser}
            updateUserStatus={updateUserStatus}
            userDeleteModalActive={userDeleteModalActive}
            setUserDeleteModalActive={setUserDeleteModalActive}
            isDeleteUserLoading={isDeleteUserLoading}
            isLoadingData={isLoadingAllEmployeesData}
          />
        )}
      </>
    </div>
  );
};

export default EmployersPage;
