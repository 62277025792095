import * as yup from 'yup';

export const rulesRuLang: RegExp = /^[А-Яа-яЁё\s,.\-/]+$/;
const rulesPassword: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,10}$/;
export const ModalEmployeeSchema = yup.object({
  employeeName: yup
    .string()
    .required('Обязательное поле')
    .min(6, 'Имя пользователя должно содержать не менее 6-ти символов')
    .max(33, 'Имя пользователя должно содержать не более 33-х символов')
    .matches(rulesRuLang, {
      message: 'Имя пользователя должно быть на русском языке',
      excludeEmptyString: true
    }),
  password: yup
    .string()
    .required('Введите пароль')
    .min(6, 'Пароль пользователя должен содержать не менее 6-ти символов')
    .max(10, 'Пароль пользователя должен содержать не более 10-ти символов')
    .matches(rulesPassword, {
      message:
        'Пароль должен содержать символы латиницы нижнего регистра, верхнего регистра и цифры',
      excludeEmptyString: true
    })
});
