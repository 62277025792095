import React, { FC } from 'react';
import classes from './Skeleton.module.scss';

const Skeleton: FC = () => {
  return (
    <div className={classes.skeleton}>
      <div className={classes.skeletonCell}></div>
    </div>
  );
};

export default Skeleton;
