import { useContext } from 'react';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AlertType } from '../../../types/enums';
import { AdminAPI } from '../../../constants/API';
import { AlertContext } from '../../../context/AlertProvider';
import { defaultSuccessMessage } from '../../../constants/alertText';

export type UserStatusInfoType = {
  userId: number;
  userStatus: UserStatusType;
};

type UserStatusType = {
  state: string;
};

export const usePutUserStatus = () => {
  const context = useContext(AlertContext);
  const {
    mutate: updateUserStatus,
    isLoading: isUpdateUserStatusLoading,
    isError
  } = useMutation(
    'updatedUserStatus',
    (data: UserStatusInfoType) =>
      axios.put(AdminAPI.putUserStatus(data.userId), data.userStatus, commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('allEmployeesData');
        queryClient
          .invalidateQueries('allSystemTopics')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );

  return { updateUserStatus, isUpdateUserStatusLoading, isError };
};
