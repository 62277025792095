import * as React from 'react';

export const handleClickOutside = (
  event: MouseEvent,
  menuElement: React.RefObject<HTMLElement>,
  setOpenMenuId: (id: string | null) => void
) => {
  const target = event.target as HTMLElement;
  if (menuElement.current && !menuElement.current.contains(target) && target.id !== 'imgMenu') {
    setOpenMenuId(null);
  }
};
