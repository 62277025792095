import React from 'react';
import styles from './CustomButton.module.scss';

// eslint-disable-next-line react/prop-types
const CustomButton = ({ content, styleType, onClick }) => {
  return styleType === 'active' ? (
    <button className={`${styles.active} ${styles.normal}`} onClick={onClick}>
      {content}
    </button>
  ) : (
    <button className={styles.normal} onClick={onClick}>
      {content}
    </button>
  );
};

export default CustomButton;
