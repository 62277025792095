import React, { FC } from 'react';
import classes from './AqentQueue.module.scss';
import QueueList from './QueueList/QueueList';
import { AgentsTicketType } from '../../../pagesMain/Agent1Page/Agent1Page';
import { AgentStatus } from '../../../types/enums';

type AgentQueueProps = {
  agentTickets: AgentsTicketType[] | [];
  activeTicket?: AgentsTicketType;
  setActiveTicket?: (data: AgentsTicketType) => void;
  agentStatus?: AgentStatus;
};

const AgentQueue: FC<AgentQueueProps> = ({
  agentTickets,
  setActiveTicket,
  activeTicket,
  agentStatus
}) => {
  return (
    <div className={classes.agentQueueContainer}>
      <div className={classes.header}>
        <div className={classes.headerTicket}>Билет</div>
        <div className={classes.headerTime}>Время</div>
        <div className={classes.headerServiceName}>Название услуги</div>
      </div>
      <QueueList
        agentTickets={agentTickets}
        setActiveTicket={setActiveTicket}
        activeTicket={activeTicket}
        agentStatus={agentStatus}
      />
    </div>
  );
};
export default AgentQueue;
