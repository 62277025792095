import { useQuery } from 'react-query';
import axios from 'axios';
import { MonitorQueueAPI } from '../../constants/API';
import { queueScreenHeaders } from '../../constants/HeadersAPI';

export type QueueHeadersRespType = {
  id: number;
  title: string;
  subTitle: string;
};

export const useGetQueueHeaders = () => {
  const {
    data: queueHeadersResp,
    isFetching: isLoadingQueueHeaders,
    isSuccess: isQueueHeadersRecieved
  } = useQuery(
    'QueueHeaders',
    () => axios.get<QueueHeadersRespType>(MonitorQueueAPI.getQueueHeaders, queueScreenHeaders()),
    {
      select: ({ data }) => data,
      refetchInterval: 30000
    }
  );

  return { queueHeadersResp, isLoadingQueueHeaders, isQueueHeadersRecieved };
};
