import React, { FC } from 'react';
import { MonitorsUnitType } from '../../QueuePanel/QueuePanel';
import { TicketStatus } from '../../../../types/enums';
import classes from './ModalQueueNewTicket.module.scss';
import classNames from 'classnames';

type ModalQueueNewTicketProps = {
  tickets: MonitorsUnitType;
};

const ModalQueueNewTicket: FC<ModalQueueNewTicketProps> = ({ tickets }) => {
  return (
    <>
      <div className={classNames(classes.modalOverlay)} />
      <div className={classNames(classes.modalContainer)}>
        <div className={classNames(classes.ticketsListContainer)}>
          {tickets.items.map((e, idx) => {
            if (e.status !== TicketStatus.TakeInWorking) {
              return (
                <div className={classes.ticketWrapper} key={idx}>
                  <div className={classNames(classes.ticketAlign, classes.ticketName)}>
                    <span>{e.itemName}</span>
                  </div>
                  <div className={classNames(classes.ticketAlign, classes.ticketIcon)} />
                  <div className={classNames(classes.ticketAlign, classes.ticketWindow)}>
                    <span>{e.window}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default ModalQueueNewTicket;
