import { useMutation } from 'react-query';
import { MonitorQueueAPI } from '../../constants/API';
import axios from 'axios';

export const useUpdateSoundStatus = () => {
  const {
    mutate: updateSoundStatus,
    isLoading,
    isError
  } = useMutation(
    ['soundStatus'],
    (id: number | null) => axios.put(MonitorQueueAPI.putSoundStatus(id), ''),
    {}
  );

  return { updateSoundStatus, isLoading, isError };
};
