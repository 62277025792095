import * as yup from 'yup';

export const rulesRuLang: RegExp = /^[А-Яа-яЁё\s,.\-/]+$/;
const advertisingTextRules = /^[\p{L}\d\s+-]+$/u;
export const InputTextSchema = yup.object({
  titleText: yup
    .string()
    .required('Обязательное поле')
    .max(55, 'Превышен лимит символов')
    .matches(advertisingTextRules, {
      message: 'Введите текст корректно',
      excludeEmptyString: true
    }),
  subTitleText: yup
    .string()
    .required('Обязательное поле')
    .max(55, 'Превышен лимит символов')
    .matches(advertisingTextRules, {
      message: 'Введите текст корректно',
      excludeEmptyString: true
    })
});

export const InputTickerSchema = yup.object({
  inputText: yup
    .string()
    .max(255, 'Превышен лимит символов')
    .required('Обязательное поле')
    .matches(advertisingTextRules, {
      message: 'Введите текст корректно',
      excludeEmptyString: true
    })
});
