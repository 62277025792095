import { useContext } from 'react';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { queryClient } from '../../../queryClient';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AlertType } from '../../../types/enums';
import { AdvertisingAPI } from '../../../constants/API';
import { AlertContext } from '../../../context/AlertProvider';
import { defaultSuccessMessage } from '../../../constants/alertText';

export const useDeleteTicker = (closeModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: deleteTicker,
    isLoading: isDeleteTickerLoading,
    isError
  } = useMutation(
    'deletedTickerStatus',
    (tickerId: number) => axios.delete(AdvertisingAPI.deleteTicker(tickerId), commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AdvertisingSettings');
        queryClient
          .invalidateQueries('allAdvertisingTickers')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
          .then(() => closeModal(false));
      }
    }
  );

  return { deleteTicker, isDeleteTickerLoading, isError };
};
