import React, { FC, useEffect } from 'react';

import classes from './AgentButtonGroup.module.scss';

import { AgentStatus } from '../../../types/enums';
import { TicketStatus } from '../../../types/enums';
import { useTicketStatus } from '../../../hooks/Agents/useTicketStatus';
import { AgentsTicketType } from '../../../pagesMain/Agent1Page/Agent1Page';

type AgentButtonGroupPropsType = {
  agentStatus: AgentStatus;
  ticketStatus: string;
  handleChangeAgentStatus: (status: AgentStatus) => void;
  setActiveTicket: (ticket: AgentsTicketType) => void;
  setEntryTicket?: (entryTicket: number | null) => void;
  ticketId: number;
  activeItems: AgentsTicketType[] | [];
  setModalRedirectID: (active: number) => void;
  setActiveModalInWorking: (value: boolean) => void;
  queueStatus: boolean | null;
  isActiveModalInWorking: boolean;
  isDisabledButtonLoading?: boolean;
};

const AgentButtonGroup: FC<AgentButtonGroupPropsType> = ({
  agentStatus,
  handleChangeAgentStatus,
  ticketId,
  setActiveTicket,
  activeItems,
  setModalRedirectID,
  setActiveModalInWorking,
  setEntryTicket,
  queueStatus,
  isActiveModalInWorking,
  isDisabledButtonLoading
}) => {
  const { mutate: changeTicketStatus } = useTicketStatus(agentStatus, setActiveModalInWorking);
  const handleClick = (agentStatus: AgentStatus, requestStatus: TicketStatus, ticketId: number) => {
    handleChangeAgentStatus(agentStatus);
    changeTicketStatus({ ticketId, requestStatus });
  };
  const handleClickNextTicket = (
    agentStatus: AgentStatus,
    requestStatus: TicketStatus,
    ticketId: number
  ) => {
    setActiveTicket(activeItems[0]);
    handleClick(agentStatus, requestStatus, ticketId);
  };

  const handleClickFinishTicket = (
    agentStatus: AgentStatus,
    requestStatus: TicketStatus,
    ticketId: number
  ) => {
    handleClick(agentStatus, requestStatus, ticketId);
    setEntryTicket && setEntryTicket(null);
    setActiveTicket(null);
  };

  useEffect(() => {
    if (isActiveModalInWorking) {
      handleChangeAgentStatus(AgentStatus.Waiting);
      setActiveTicket(null);
    }
  }, [isActiveModalInWorking]);
  return (
    <div className={classes.container}>
      <button
        type="button"
        onClick={() =>
          handleClickNextTicket(
            AgentStatus.Requesting,
            TicketStatus.CallFirstClientInQueue,
            activeItems[0].id
          )
        }
        className={classes.nextBtn}
        disabled={agentStatus !== AgentStatus.Waiting || !queueStatus || isDisabledButtonLoading}>
        следующий
      </button>
      <button
        type="button"
        disabled={agentStatus !== AgentStatus.Waiting || !ticketId || isDisabledButtonLoading} //todo change to selecting when added queue
        onClick={() =>
          handleClick(AgentStatus.Requesting, TicketStatus.CallPriorityClientinQueue, ticketId)
        }>
        вызов
      </button>
      <button
        type="button"
        disabled={agentStatus !== AgentStatus.Requesting || isDisabledButtonLoading}
        onClick={() => handleClick(AgentStatus.Working, TicketStatus.TakeInWorking, ticketId)}>
        в работу
      </button>
      <button //`items/${itemId}/redirect`
        type="button"
        disabled={agentStatus !== AgentStatus.Working || isDisabledButtonLoading}
        className={classes.redirectBtn}
        onClick={() => {
          setModalRedirectID(ticketId);
        }}>
        перенаправить
      </button>
      <button
        type="button"
        disabled={agentStatus !== AgentStatus.Requesting || isDisabledButtonLoading}
        onClick={() =>
          handleClick(AgentStatus.Requesting, TicketStatus.RepaetedCallClient, ticketId)
        }>
        повторный вызов
      </button>
      <button
        type="button"
        disabled={agentStatus !== AgentStatus.Requesting || isDisabledButtonLoading}
        onClick={() =>
          handleClickFinishTicket(AgentStatus.Waiting, TicketStatus.DeleteClientInQueue, ticketId)
        }>
        не пришел
      </button>
      <button
        type="button"
        disabled={agentStatus !== AgentStatus.Working || isDisabledButtonLoading}
        onClick={() =>
          handleClickFinishTicket(AgentStatus.Waiting, TicketStatus.FinishClientInQueue, ticketId)
        }>
        завершить работу
      </button>
    </div>
  );
};

export default AgentButtonGroup;
