import { CategoriesTableType } from '../../../types/Admin/CategoriesTopicsTypes';

export function getTopicsByCategoryId(sectionsArr: CategoriesTableType[], sectionId: number) {
  const section = sectionsArr?.find((section: CategoriesTableType) => section.id === sectionId);

  if (section && section.topics && section.topics.length > 0) {
    return section.topics.map((topic) => ({ id: topic.id, nameRu: topic.nameRu }));
  } else {
    return [];
  }
}
