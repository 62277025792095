import React from 'react';
import { FC } from 'react';
import classes from './QueueList.module.scss';
import { AgentsTicketType } from '../../../../pagesMain/Agent1Page/Agent1Page';
import { format } from 'date-fns';
import { AgentStatus } from '../../../../types/enums';
import classNames from 'classnames';

type QueueListProps = {
  agentTickets: AgentsTicketType[] | [];
  activeTicket?: AgentsTicketType;
  setActiveTicket?: (data: AgentsTicketType) => void;
  agentStatus?: AgentStatus;
};
export const QueueList: FC<QueueListProps> = ({
  agentTickets,
  setActiveTicket,
  activeTicket,
  agentStatus
}) => {
  return (
    <div className={classes.container}>
      {agentTickets &&
        agentTickets.map((item: AgentsTicketType) => (
          <div
            key={item.id}
            className={classNames(classes.row, {
              [classes.activeTicket]: activeTicket && activeTicket.id === item.id,
              [classes.hover]: setActiveTicket
            })}
            onClick={
              setActiveTicket && agentStatus === AgentStatus.Waiting
                ? () => setActiveTicket(item)
                : null
            }>
            <div className={classes.rowTicket}>{item.name}</div>
            <div className={classes.rowTime}>{format(item.dateTime, 'HH:mm')}</div>
            <div className={classes.rowServiceName}>{item.topicName}</div>
            <div className={classes.bottomLine}></div>
            {!item.status && <div className={classes.crossOutLine}></div>}
          </div>
        ))}
    </div>
  );
};
export default QueueList;
