import { useMutation } from 'react-query';
import { AuthorizationAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { AlertContext } from '../../context/AlertProvider';
import { useContext } from 'react';
import { AlertType } from '../../types/enums';
import { defaultSuccessMessage } from '../../constants/alertText';

type UserAuthInfoType = {
  serviceUnitId: number;
};

export const usePutUserAuthInfo = (
  setWorkPlaceSelected: (status: boolean) => void,
  setActiveModal: (status: boolean) => void
) => {
  const context = useContext(AlertContext);
  const {
    mutate: putUserAuthInfo,
    isLoading: isLoadingPutUserAuthInfo,
    isError,
    isSuccess: isSuccesPutUserAuthInfo
  } = useMutation(
    ['userAuthInfo'],
    (data: UserAuthInfoType) => axios.put(AuthorizationAPI.putUserAuthInfo, data, commonHeaders()),
    {
      onSuccess: () => {
        setWorkPlaceSelected(true);
        setActiveModal(false);
        context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage });
      }
    }
  );

  return { putUserAuthInfo, isSuccesPutUserAuthInfo, isLoadingPutUserAuthInfo, isError };
};
