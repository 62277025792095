import React, { FC } from 'react';

import styles from './TabsAR.module.scss';

type TabsProps = {
  tabs: string[];
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  setSelectedDate?: (date: string) => void;
  selectedStartDate?: string;
  selectedEndDate?: string;
  setSelectedStartDate?: (date: string) => void;
  setSelectedEndDate?: (date: string) => void;
};

const Tabs: FC<TabsProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  selectedStartDate,
  selectedEndDate,
  setSelectedStartDate,
  setSelectedEndDate
}) => {
  const handleButtonClick = (content: string) => {
    setActiveTab(content);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStartDate(event.target.value);
    if (selectedEndDate < event.target.value) {
      setSelectedEndDate(event.target.value);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value >= selectedStartDate) {
      setSelectedEndDate(event.target.value);
    }
  };

  return (
    <div className={styles.tabs}>
      {tabs.map((e, idx) => (
        <React.Fragment key={idx}>
          <div
            className={activeTab === e ? `${styles.tab} ${styles.active}` : styles.tab}
            onClick={() => handleButtonClick(e)}>
            {e}
          </div>
        </React.Fragment>
      ))}
      {activeTab === 'Период' && (
        <div className={styles.timePickerWrapper}>
          с <input type="date" value={selectedStartDate} onChange={handleStartDateChange} />
          по <input type="date" value={selectedEndDate} onChange={handleEndDateChange} />
        </div>
      )}
    </div>
  );
};

export default Tabs;
