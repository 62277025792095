import React from 'react';
import classes from './LoadingPrintSpinner.module.scss';

export const LoadingPrintSpinner = () => {
  return (
    <svg className={classes.spinner} viewBox="0 0 50 50">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: 'rgba(144, 144, 145, 0.2)' }} />
          <stop offset="100%" style={{ stopColor: '#0066FF' }} />
        </linearGradient>
      </defs>
      <circle
        className={classes.path}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        stroke="url(#gradient)"></circle>
    </svg>
  );
};
