import { Category, SectionOrServiceForTable } from '../../../types/Admin/CategoriesTopicsTypes';

export function modifyAllCategoriesTopicsData(categories: Category[]): SectionOrServiceForTable[] {
  return categories.map((row: Category) => {
    const convertedRow: SectionOrServiceForTable = {
      id: row.id,
      nameRu: row.nameRu,
      nameBe: row.nameBe,
      nameEn: row.nameEn,
      isActive: row.isActive,
      categoryCounter: row.subCategories.length.toString(),
      topicCounter: row.topics.length.toString(),
      itemNamePrefix: '-',
      startFinishWork: `${row.startWork}-${row.endWork}`,
      radioType: row.topics ? 'section' : 'service',
      subRows: [],
      isParentCategory: row.isParentCategory,
      ...(!row.isParentCategory && { parentCategory: row.parentCategory })
    };

    if (row.topics && row.topics.length > 0) {
      convertedRow.subRows = row.topics.map((topic) => ({
        id: topic.id,
        nameRu: topic.nameRu,
        nameBe: topic.nameBe,
        nameEn: topic.nameEn,
        isActiveTopic: topic.isActiveTopic,
        isActiveLinkToCategory: topic.isActiveLinkToCategory,
        categoryCounter: '0',
        topicCounter: '0',
        itemNamePrefix: topic.itemNamePrefix ? topic.itemNamePrefix : '-',
        startFinishWork: `${topic.startWork}-${topic.endWork}`,
        radioType: 'service',
        subRows: []
      }));
    }
    if (row.subCategories && row.subCategories.length > 0) {
      const subrows = modifyAllCategoriesTopicsData(row.subCategories);
      convertedRow.subRows.push(...subrows);
    }
    return convertedRow;
  });
}
