const UserAreaTestData = {
  company: '',
  location: '',
  license: 4,
  licenseStatus: true,
  licenseExpirationDate: '31.12.2026',
  pilotStatus: false,
  pilotStatusExpirationDate: '01.02.2024'
};

export default UserAreaTestData;
