import { useQuery } from 'react-query';
import { CategoriesTopicsTableAPI } from '../../../constants/API';
import axios from 'axios';
import { CategoriesServicesObjectData } from '../../../types/Admin/CategoriesTopicsTypes';
import { getCategoriesOrTopicsData } from '../../../utils/Admin/CategoriesAndServicesTable/getCategoriesOrTopicsData';
import { modifyAllCategoriesTopicsData } from '../../../utils/Admin/CategoriesAndServicesTable/modifyAllCategoriesTopicsData';
import { commonHeaders } from '../../../constants/HeadersAPI';

const transformData = ({ data }): CategoriesServicesObjectData => {
  if (data) {
    return {
      all: modifyAllCategoriesTopicsData(data.categories),
      sections: getCategoriesOrTopicsData(data).categoriesArr,
      services: getCategoriesOrTopicsData(data).topicsArr
    };
  }
};

export const useGetAllCategoriesTopics = () => {
  const {
    data: allCategoriesTopicsResp,
    isLoading: isLoadingAllCategoriesTopics,
    isSuccess: isAllCategoriesTopicsReceived
  } = useQuery(
    ['allCategoriesTopics'],
    () => axios.get(CategoriesTopicsTableAPI.getAllCategoriesTopics, commonHeaders()),
    {
      select: transformData
    }
  );
  return {
    allCategoriesTopicsResp,
    isLoadingAllCategoriesTopics,
    isAllCategoriesTopicsReceived
  };
};
