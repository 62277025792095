export enum AgentStatus {
  Waiting = 'waiting',
  Selecting = 'selecting',
  Requesting = 'requesting',
  Working = 'working'
}

export enum EmployerAccessLevel {
  admin = 'Администратор',
  operatorIn = 'Оператор на въезд',
  operatorOut = 'Оператор на выезд',
  agentAdvanced = 'Агент с расширенными правами',
  agentLimited = 'Агент с ограниченными правами'
}

export enum TicketStatus {
  CallFirstClientInQueue = 'Вызван по порядку',
  CallPriorityClientinQueue = 'Вызван вне очереди',
  RepaetedCallClient = 'Повторный вызов',
  TakeInWorking = 'В обработке',
  DeleteClientInQueue = 'Аннулирован',
  FinishClientInQueue = 'Обработан',
  SkippedClient = 'Пропущен'
}

export enum TransportCategories {
  ContainerShips = 'К',
  HeavyPlatform = 'Т',
  Warehouse = 'С',
  RestCategory = ''
}

export enum Languages {
  Ru = 'ru',
  By = 'by',
  En = 'en'
}

export enum ThemeColors {
  Blue = 'blue',
  White = 'white',
  Black = 'black'
}

export enum AdvertisingModals {
  TableVideo = 'modalTableVideo',
  TickerBoard = 'modalTickerBoard',
  TableText = 'modalTableText',
  StandTicker = 'modalStandTicker'
}

export enum ActionTypeInEditMenu {
  Activate = 'Активировать',
  Deactivate = 'Деактивировать'
}

export enum TableName {
  All = 'Все',
  Sections = 'Разделы',
  Services = 'Услуги'
}

export enum TableType {
  All = 'all',
  Sections = 'sections',
  Services = 'services'
}

export enum AlertType {
  Success = 'success',
  Error = 'error'
}
export enum SectionOrCategory {
  Section = 'section',
  Service = 'service'
}
export enum ModalMode {
  Edit = 'edit',
  Create = 'create'
}

export enum NoEmptyParkingSlotsMessage {
  Message = 'Все парковочные места этой категории заняты'
}

export enum TimeMode {
  Start = 'start',
  End = 'end'
}

export enum ActivateUser {
  Activate = 'Активировать',
  Deactivate = 'Деактивировать'
}

export enum ActivateContent {
  Activate = 'Активировать',
  Deactivate = 'Деактивировать'
}

export enum ModalTickerMode {
  Create = 'Create',
  Edit = 'Edit'
}

// Analytics and Reports Headers
export enum EmployeesHeader {
  employer = 'Сотрудник',
  calledFromQueue = 'Вызвано из очереди',
  served = 'Обслужено',
  redirected = 'Перенаправлено',
  missed = 'Не пришли',
  averageServiceTime = 'Сред. время обслуживания',
  averageUnActiveTime = 'Сред. время простоя'
}

export enum VisitorsHeader {
  date = 'Дата',
  registred = 'Зарегистрировано',
  calledFromQueue = 'Вызвано из очереди',
  served = 'Обслужено',
  redirected = 'Перенаправлено',
  missed = 'Не пришли',
  averagePendingTime = 'Сред. время очереди',
  averageServiceTime = 'Сред. время обслуживания'
}

export enum ServicesHeader {
  date = 'Дата',
  serviceName = 'Наименование услуги',
  registredServices = 'Зарегистрировано',
  calledFromQueue = 'Вызвано из очереди',
  served = 'Обслужено',
  redirected = 'Перенаправлено',
  missed = 'Не пришли',
  averageServiceTime = 'Сред. время обслуживания'
}

export enum ContainerNumberHeader {
  num = '№п/п',
  containerNumber = 'Номер контейнера',
  date = 'Дата',
  company = 'Юридическое лицо',
  carNumber = '№ автомобиля',
  arrivalTime = 'Время заезда',
  insideTerritoryTime = 'Время на территории',
  departureTime = 'Время выезда',
  cargoImport = 'Ввоз груза',
  cargoExport = 'Вывоз груза'
}

export enum CarNumberHeader {
  num = '№п/п',
  carNumber = 'Номер автомобиля',
  date = 'Дата',
  category = 'К-номер контейнера/ склад/тяжеловесная площадка',
  company = 'Юридическое лицо',
  arrivalTime = 'Время заезда',
  insideTerritoryTime = 'Время на территории',
  departureTime = 'Время выезда',
  cargoImport = 'Ввоз груза',
  cargoExport = 'Вывоз груза'
}

export enum CompanyTodaysHeader {
  serialNumber = '№п/п',
  company = 'Юридическое лицо',
  carNumber = 'Номер автомобиля',
  entryTime = 'Время въезда',
  exitTime = 'Время выезда',
  timeOnTerritory = 'Время на территории',
  timeInQueue = 'Время ожидания в очереди',
  containerNumber = 'К-номер контейнера/ склад/тяжеловесная площадка',
  cargoImport = 'Ввоз груза',
  cargoExport = 'Вывоз груза'
}

export enum CompanyPeriodHeader {
  serialNumber = '№п/п',
  company = 'Юридическое лицо',
  importOfCargo = 'Ввоз груза',
  exportOfCargo = 'Вывоз груза',
  averageTimeOnTerritory = 'Время (сред.) на территории',
  averageTimeInQueue = 'Время (сред.) ожидания в очереди',
  containerQuantity = 'Контейнеры - кол-во',
  heavyPlatformQuantity = 'Тяжеловесная площадка - кол-во',
  warehouseQuantity = 'Склад - кол-во'
}

export enum StationPeriodHeader {
  identity = 'Наименование',
  importOfCargo = 'Ввоз груза',
  exportOfCargo = 'Вывоз груза',
  averageTimeOnTerritory = 'Время (сред.) на территории',
  averageTimeInQueue = 'Время (сред.) ожидания в очереди',
  vehicleCount = 'Кол-во транспорта'
}

export enum AdvertisingElements {
  VideoPlaylist = 'Табло видео',
  TickersList = 'Бегущая строка',
  Headers = 'Заголовки'
}
