import classes from './ButtonsEditLanguage.module.scss';
import classNames from 'classnames';
import React, { FC } from 'react';

type ButtonsEditLanguage = {
  language: string;
  theme: string;
  handleChangeLanguage: (language: string) => void;
};
export const ButtonsEditLanguage: FC<ButtonsEditLanguage> = ({
  language,
  theme,
  handleChangeLanguage
}) => {
  const themeClass: string =
    theme === 'blue'
      ? classes.blueTheme
      : theme === 'white'
        ? classes.whiteTheme
        : classes.blackTheme;

  return (
    <div id={themeClass} className={classes.btnContainer}>
      {['ru', 'en', 'by'].map((lang) => (
        <button
          key={lang}
          className={classNames(classes[`${lang}Btn`], {
            [classes.active]: language === lang
          })}
          onClick={() => handleChangeLanguage(lang)}>
          <span>{lang === 'ru' ? 'РУС' : lang === 'en' ? 'EN' : 'БЕЛ'}</span>
        </button>
      ))}
    </div>
  );
};
