import classNames from 'classnames';
import classes from './ModalRedirection.module.scss';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ReactSelect, { components } from 'react-select';
import {
  AgentType,
  topicItem,
  useCategoriesTopics
} from '../../../../hooks/RedirectService/useCategoriesTopics';
import { ModalRedirectEmployeeSchema } from '../../../../schemas/ModalRedirectEmployeeSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  RedirectDataType,
  useRedirectToOtherEmployee
} from '../../../../hooks/RedirectService/useRedirectToOtherEmployee';
import { ReactComponent as ArrowLogo } from '../../../../assets/icons/rowSelect.svg';
import { AgentStatus } from '../../../../types/enums';
import { LoadingSpinner } from '../../../Admin/Spinner/LoadingSpinner';

type ModalRedirectionProps = {
  setActive: (value: number | null) => void;
  handleChangeAgentStatus: (status: AgentStatus) => void;
  itemId: number;
  setActiveTicket: (value: string | null) => void;
};
type categoryOptionsType = {
  value: number;
  label: string;
};
type FormData = yup.InferType<typeof ModalRedirectEmployeeSchema>;

export const ModalRedirection: FC<ModalRedirectionProps> = ({
  setActive,
  itemId,
  handleChangeAgentStatus,
  setActiveTicket
}) => {
  const { data } = useCategoriesTopics();
  const { redirectToOtherEmployee, isLoadingRedirectionEmployee } =
    useRedirectToOtherEmployee(itemId);
  const [employeeValue, setEmployeeValue] = useState(null);
  const [topicValue, setTopicValue] = useState(null);

  const EmployeesOptions = data?.agents?.map((agent: AgentType) => ({
    value: agent.id,
    label: agent.name
  }));

  const TopicsOptions = data?.agents?.reduce((acc: categoryOptionsType[], agent: AgentType) => {
    if (agent?.name === employeeValue?.label) {
      acc.push(
        ...agent.topics.map((topic: topicItem) => ({
          value: Number(topic.id),
          label: topic.name
        }))
      );
    }
    return acc;
  }, []);

  const getEmployeesValue = (value: string) =>
    value ? EmployeesOptions.find((option) => option.label === value) : '';
  const getServicesValue = (value: string) =>
    value ? TopicsOptions.find((option) => option.label === value) : '';
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    control,
    watch
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(ModalRedirectEmployeeSchema),
    defaultValues: {
      employeeId: '',
      serviceId: '',
      comment: ''
    }
  });
  const onSubmit: SubmitHandler<RedirectDataType> = () => {
    setActive(null);
    handleChangeAgentStatus(AgentStatus.Waiting);
    setActiveTicket(null);
    redirectToOtherEmployee({
      topicId: topicValue.value,
      comment: watch('comment'),
      serviceUnitId: employeeValue.value
    });
  };
  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowLogo className={classes.arrow} />
      </components.DropdownIndicator>
    );
  };
  return (
    <div className={classNames(classes.modal, classes.active)}>
      <div className={classes.modalRedirectionContainer}>
        <form>
          {isLoadingRedirectionEmployee && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <LoadingSpinner />
                <p>Сохранение...</p>
              </div>
            </div>
          )}
          <h2 className={classes.title}>Перенаправление на другого сотрудника</h2>
          <div className={classes.employeesContainer}>
            <Controller
              control={control}
              name="employeeId"
              render={({ field: { onChange, value: employeeValue } }) => (
                <div className={classes.employeesSelector}>
                  <label className={classes.selectLabel}>Сотрудник</label>
                  <ReactSelect
                    placeholder={''}
                    classNamePrefix={'custom-select'}
                    noOptionsMessage={() => 'Выберите сотрудника'}
                    options={EmployeesOptions}
                    value={getEmployeesValue(employeeValue)}
                    onChange={(newValue: categoryOptionsType) => {
                      onChange(newValue.label);
                      setEmployeeValue(newValue);
                      setValue('serviceId', '');
                    }}
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                  />
                  {errors.employeeId?.message && (
                    <p className={classes.error}>{errors.employeeId.message}</p>
                  )}
                </div>
              )}
            />
          </div>
          <div className={classes.servicesContainer}>
            <Controller
              control={control}
              name="serviceId"
              render={({ field: { onChange, value: serviceValue } }) => (
                <div className={classes.servicesSelector}>
                  <label
                    className={classNames(classes.selectLabel, {
                      [classes.disabled]: !employeeValue
                    })}>
                    Услуга
                  </label>
                  <ReactSelect
                    placeholder={''}
                    classNamePrefix={'custom-select'}
                    options={TopicsOptions}
                    value={getServicesValue(serviceValue)}
                    onChange={(newValue: categoryOptionsType) => {
                      onChange(newValue.label);
                      setTopicValue(newValue);
                    }}
                    isDisabled={!employeeValue}
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                  />
                  {errors.serviceId?.message && (
                    <p className={classes.error}>{errors.serviceId.message}</p>
                  )}
                </div>
              )}
            />
          </div>
          <div className={classes.commentContainer}>
            <label htmlFor="comment" className={classes.selectLabel}>
              Комментарий
            </label>
            <textarea {...register('comment')} id="comment" value={watch('comment')} />
            {errors.comment?.message && <p className={classes.error}>{errors.comment.message}</p>}
          </div>
          <div className={classes.btnsContainer}>
            <button className={classes.btnCancel} onClick={() => setActive(null)} type="button">
              Отмена
            </button>
            <button
              className={classes.btnRedirect}
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(errors).length > 0}
              type="button">
              Перевести
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
