import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';

type CurrentServicesQueueType = {
  topicName: string;
  clientInQueue: string;
  avgTime: string;
};

export const useGetCurrentServicesQueue = () => {
  const {
    data: currentServicesQueueResp,
    isFetching: isLoadingCurrentServicesQueue,
    isSuccess: isCurrentServicesQueueRecieved
  } = useQuery(
    ['currentServicesQueue'],
    () =>
      axios.get<CurrentServicesQueueType[]>(
        AdminAnalyticsAndReportsAPI.getCurrentServicesStatusQueue,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e) => {
            return {
              serviceName: e.topicName,
              queue: e.clientInQueue,
              queuingTime: e.avgTime
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    currentServicesQueueResp,
    isLoadingCurrentServicesQueue,
    isCurrentServicesQueueRecieved
  };
};
