import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';

import classes from './CurentServicesStatusTables.module.scss';
import { FC } from 'react';
import Skeleton from '../../../Skeleton/Skeleton';

type CurrentServicesStatusTableType = {
  workPlace: string;
  employer: string;
  role: string;
  status: string;
  currentService: string;
  serviceTimeForCurrentService: string;
};

const columnSizes = {
  workPlaceSize: 200,
  employerSize: 400,
  roleSize: 200,
  statusSize: 200,
  currentServiceSize: 200,
  serviceTimeForCurrentServiceSize: 1200,
  serviceTimeForCurrentServiceMinSize: 50
};

type CurrentServicesStatusTableEmployersProps = {
  EmployeesTableData: CurrentServicesStatusTableType[] | [];
  isLoadingData: boolean;
};

const {
  workPlaceSize,
  employerSize,
  roleSize,
  statusSize,
  currentServiceSize,
  serviceTimeForCurrentServiceSize,
  serviceTimeForCurrentServiceMinSize
} = columnSizes;

const CurrentServicesStatusTableEmployers: FC<CurrentServicesStatusTableEmployersProps> = ({
  EmployeesTableData,
  isLoadingData
}) => {
  const columnHelper = createColumnHelper<CurrentServicesStatusTableType>();

  const categoriesColumns = [
    columnHelper.accessor('workPlace', {
      header: () => <div className={classes.headerContent}>Рабочее место</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: workPlaceSize
    }),
    columnHelper.accessor('employer', {
      header: () => <div className={classes.headerContent}>Сотрудник</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: employerSize
    }),
    columnHelper.accessor('role', {
      header: () => <div className={classes.headerContentCenter}>Тип доступа</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: roleSize
    }),
    columnHelper.accessor('status', {
      header: () => <div className={classes.headerContentCenter}>Статус</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: statusSize
    }),
    columnHelper.accessor('currentService', {
      header: () => <div className={classes.headerContentCenter}>Текущая услуга</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: currentServiceSize
    }),
    columnHelper.accessor('serviceTimeForCurrentService', {
      header: () => (
        <div className={classes.headerContentRight}>Время обслуживания по текущей услуге</div>
      ),
      cell: ({ getValue }) => <div className={classes.columnContentRight}>{getValue()}</div>,
      size: serviceTimeForCurrentServiceSize,
      minSize: serviceTimeForCurrentServiceMinSize
    })
  ];

  const columns = categoriesColumns;
  const [activeRow, setActiveRow] = React.useState(null);

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : EmployeesTableData || []),
    [isLoadingData, EmployeesTableData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: `${header.getSize()}px` }}>
                    <div className={classes.thTitle}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                        }}>
                        <div className={classes.colorResizer} />
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => handleClick(row.id)}
              className={activeRow === row.id ? classes.activeRow : ''}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CurrentServicesStatusTableEmployers;
