import CustomButton from '../CustomButton';
import css from './HeaderAnalyticsTables.module.scss';
import React from 'react';
import TabsAR from '../TabsAnalyticsAndReports/TabsAR';

type HeaderAnalyticsTablesProps = {
  name: string;
  isActiveButtonCSV: boolean;
  tabs: string[];
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  selectedStartDate?: string;
  selectedEndDate?: string;
  setSelectedStartDate?: (date: string) => void;
  setSelectedEndDate?: (date: string) => void;
  handleSaveAsCSV?: () => void;
};

const HeaderAnalyticsTables = ({
  name,
  isActiveButtonCSV,
  tabs,
  activeTab,
  setActiveTab,
  selectedStartDate,
  selectedEndDate,
  setSelectedStartDate,
  setSelectedEndDate,
  handleSaveAsCSV
}: HeaderAnalyticsTablesProps) => {
  return (
    <div className={css.container}>
      <div className={css.contentHeader}>
        <div className={css.headerGroup}>
          <div className={css.title}>{name}</div>
          <TabsAR
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedStartDate={setSelectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
          />
        </div>
        <div className={css.btn_container}>
          {isActiveButtonCSV && (
            <CustomButton
              content={'Экспорт CSV'}
              styleType={'active'}
              onClick={() => handleSaveAsCSV()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderAnalyticsTables;
